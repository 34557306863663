<template lang="pug">
div
  .card.mt-3.py-3.px-2(v-if="currentOrder")
    div
      .text-light.fw-medium.float-end Tisch {{currentOrder.table_number}} - {{currentOrder.count}} Gäste
      .text-light.fw-medium {{currentOrder.drinks_flat_rate}}
    h3.mt-2.mb-2 {{currentOrder.name}}
    .mt-4(v-if="currentOrderState==='book'")
      div(v-if="currentProduct")
        h2 {{currentProduct.name}}
        .mt-1(v-if="!isCategoryFree(currentProductCategory)"): small.text-danger {{toCurrency(currentProduct.price)}}
        .row.mt-5
          .col-3: .d-grid: button.btn.btn-secondary(type="button" @click="amount=amount-1" :disabled="amount == 1" style="height: 53px;") -
          .col: input.form-control(aria-label='Anzahl' v-model="amount" style="text-align: center; font-weight: 800; font-size: 24px;" type="number" min="1")
          .col-3: .d-grid: button.btn.btn-secondary(type="button" @click="amount=amount+1" style="height: 53px;") +
        .d-grid: button.btn.btn-primary.mt-5.btn-lg(type="button" @click="sendBooking"): strong BUCHEN
        ul.list-group.mt-5
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentProduct=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück

      div(v-else-if="currentProductCategory")
        span.text-light.fw-medium {{currentProductCategory}} wählen:
        ul.list-group.mt-2
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentProductCategory=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück
          li.list-group-item.d-flex.justify-content-between.align-items-center(v-for="p in productsByCategory(currentProductCategory)" style="cursor: pointer;" @click="currentProduct=p")
            div
              h3.m-0 {{p.name}}
              .mt-1(v-if="!isCategoryFree(currentProductCategory)"): small.text-danger {{toCurrency(p.price)}}
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
      div(v-else)
        span.text-light.fw-medium Kategorie wählen:
        ul.list-group.mt-2
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrderState=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück
          li.list-group-item.d-flex.justify-content-between.align-items-center(v-for="c in productCategories" style="cursor: pointer;" @click="currentProductCategory=c")
            div
              h3.m-0 {{c}}
              .mt-2(v-if="!isCategoryFree(c)"): small.text-danger Nicht durch {{currentOrder.drinks_flat_rate}} abgedeckt.
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
    .mt-4(v-else-if="currentOrderState==='payment'")
      div(v-if="payment")
        span.text-light.fw-medium Betrag:
        input.form-control.mb-3(aria-label='Betrag' v-model="openAmount" style="text-align: center; font-weight: 800; font-size: 24px;" type="number" :min="regularAmount")
        div(v-if="payment === 'card'")
          span.text-light.fw-medium Terminal:
          select.form-control.mb-3(aria-label='Terminal' v-model="terminalId" style="text-align: center; font-weight: 800; font-size: 24px;")
            option(value="1") Terminal 1
            option(value="2") Terminal 2
        .d-grid: button.btn.btn-primary.mt-3.btn-lg(type="button" @click="sendPayment" :disabled="regularAmount > openAmount"): strong BUCHEN
        ul.list-group.mt-4
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück


      div(v-else)
        span.text-light.fw-medium Zahlungsmethode wählen:
        ul.list-group.mt-2
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment='bar'")
            h3.m-0 Bar
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment='card'")
            h3.m-0 Kartenzahlung
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment='invoice'")
            h3.m-0 Auf Rechnung
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrderState=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück
    .mt-4(v-else)
      span.text-light.fw-medium Aktion wählen:
      ul.list-group.mt-2
        li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrderState='book'")
          h3.m-0 Getränk buchen
          svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
            path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
        li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrderState='payment'")
          h3.m-0 Gast abrechnen
          svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
            path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
        li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrder=null")
          h3.m-0
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
            | Zurück
    .mt-4(v-if="orders && orders.length != 0 && !currentOrderState || currentOrderState == 'payment' && payment")
      span.text-light.fw-medium Gebucht:
      table.table.mt-3
        tr
          th(v-if="payment")
          th Produkt
          th.text-end Preis
        tr(v-for="o in orders" :style="{opacity: o.paid ? 0.5 : 1}")
          th(v-if="payment"): input(class="form-check-input" type="checkbox" v-model="o.checked" :disabled="o.free || o.paid")
          td.nw: div
            | {{o.product.name}}
            .small.text-light.fw-medium.m-0.p-0 {{o.user_name}} - {{o.time}}
          td.text-end
            span(v-if="o.free") GP
            span(v-else) {{toCurrency(o.product.price)}}
        tr
          th(v-if="payment")
          th: .text-end Summe
          th: .text-end {{toCurrency(sumOfOrders)}}
        tr
          th(v-if="payment")
          th: .text-end Bereits gezahlt
          th: .text-end {{toCurrency(sumOfPayments)}}
        tr
          th(v-if="payment")
          th: .text-end Offen
          th: .text-end {{toCurrency(sumOfOrders-sumOfPayments)}}
  .card.mt-3.py-3.px-2(v-else-if="currentTableNumber")
    span.text-light.fw-medium Partei an Tisch {{currentTableNumber}} auswählen:
    .mt-3
      ul.list-group
        li.list-group-item.d-flex.justify-content-between.align-items-center(v-for="order in availableTables[currentTableNumber]" style="cursor: pointer;" @click="currentOrder=order")
          h3.m-0 {{order.name}}
          //- span.badge.badge-center.bg-primary 5
          svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
            path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
        li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentTableNumber=null")
          h3.m-0
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
            | Zurück

  .card.mt-3.py-3.px-2(v-else)
    span.text-light.fw-medium Tisch auswählen:
    .mt-3
      ul.list-group
        li.list-group-item.d-flex.justify-content-between.align-items-center(v-for="tableNumber in  availableTableNumbers" style="cursor: pointer;" @click="currentTableNumber=tableNumber")
          h2.m-0 Tisch {{tableNumber}}
          //- span.badge.badge-center.bg-primary 5
          svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
            path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')

</template>

<script>
export default {
  components: {
  },
  props: [
    "_event"
  ],
  data () {
    return {
      terminalId: 1,
      amount: 1,
      regularAmount: 0,
      openAmount: 0,
      payment: null,
      currentProduct: null,
      currentProductCategory: null,
      currentOrderState: null,
      currentTableNumber: null,
      currentOrder: null,
      products: [],
      payments: [],
      orders: null,
    }
  },
  mounted () {
    fetch("/gastro/products.json").then(x => x.json()).then((response) => {
      this.products = response
    })
  },
  watch: {
    currentOrder(order){
      if (!order) return false
      fetch("/gastro/orderbird/orders/"+this.currentTableNumber+"/"+this.currentOrder.id+".json").then(x => x.json()).then((response) => {
        this.payments = response.payments
        this.orders = response.orders
      })
    }, 
    payment(payment){
      if (!this.currentOrderState) return false
      if (!this.currentOrder) return false
      this.calcAmount()
      fetch("/gastro/orderbird/orders/"+this.currentTableNumber+"/"+this.currentOrder.id+".json").then(x => x.json()).then((response) => {
        this.payments = response.payments
        this.orders = response.orders
        this.calcAmount()
      })
    },
  },
  computed: {
    sumOfOrders(){
      if (!this.orders || this.orders.length == 0) return 0.00
      const sum = this.orders.map( x => {
        if (this.payment ? (!x.checked && !x.paid || x.free) : x.free){
          return 0.0
        } else {
          return x.product.price
        }
      }).reduce((b, a) => b + a, 0);
      if (this.payment) this.calcAmount(sum)
      return sum
    },
    sumOfPayments(){
      return this.payments.map( x => x.regular_amount).reduce((b, a) => b + a, 0);
    },
    productCategories(){
      return Object.keys(Object.groupBy(this.products, (x) => x.category)).sort();
    },
    tableNumbersForUser(){
      return [107, 108, 109, 110]
    },
    availableTables(){
      let tickets = this.event.tickets.filter(x => this.tableNumbersForUser.includes(x.table_number)).map(x => {
        return JSON.stringify({
          id: x.order_id, 
          name: x.name,
          drinks_flat_rate: x.drinks_flat_rate_name,
          table_number: x.table_number,
          count: this.event.tickets.filter(xx => x.order_number == xx.order_number).length
        })
      })
      tickets =  [...new Set(tickets)].map(x => JSON.parse(x))
      return Object.groupBy(tickets, (x) => x.table_number)
    },
    availableTableNumbers(){
      return Object.keys(this.availableTables)
    },
    event(){
      return JSON.parse(this._event)
    }
  },
  methods: {
    calcAmount(sum){
      this.regularAmount = (sum||this.sumOfOrders)-this.sumOfPayments
      this.openAmount = (sum||this.sumOfOrders)-this.sumOfPayments
    },
    sendPayment(){
      const params = {
        regular_amount: this.regularAmount,
        amount: this.openAmount,
        order_id: this.currentOrder.id,
        payment_method: this.payment,
        terminal_id: this.terminalId,
        gastro_order_ids: this.orders.filter(x => x.checked).map(x => x.id),
        table_number: this.currentTableNumber
      }

      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify(params),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      fetch("/gastro/orderbird/payment", options).then(x => x.json()).then((response) => {
        this.orders = response.orders
        this.payments = response.payments
      })

      this.currentOrderState = null
      this.payment = null
    },
    sendBooking(){
      const params = {
        order_id: this.currentOrder.id,
        product_id: this.currentProduct.id,
        amount: this.amount,
        free: this.isCategoryFree(this.currentProductCategory),
        table_number: this.currentTableNumber
      }

      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify(params),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      fetch("/gastro/orderbird/book", options).then(x => x.json()).then((response) => {
        this.orders = response.orders
        this.payments = response.payments
      })

      this.amount = 1
      this.currentProduct = null
      this.currentProductCategory = null
      this.currentOrderState = null
    },
    isCategoryFree(category){
      if (this.currentOrder.drinks_flat_rate.includes("Basic")){
        return ['Softs', 'Säfte & Schorlen', 'Rotweine', 'Roséweine', 'Heiße Getränke', 'Biere', 'Weißweine', 'Wasser'].includes(category)
      } else if (this.currentOrder.drinks_flat_rate.includes("Basic")){
        return ['Softs', 'Säfte & Schorlen', 'Rotweine', 'Sekt', 'Roséweine', 'Heiße Getränke', 'Biere', 'Weißweine', 'Wasser'].includes(category)
      } else if (this.currentOrder.drinks_flat_rate.includes("VIP")){
        return true
      } else {
        return false
      }
    },
    productsByCategory(category){
      return this.products.filter(x => x.category == category)
    },
    toCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    }
  }
}
</script>

<style lang="scss">
</style>