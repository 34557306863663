<template lang="pug">
div
  #no-more-tables: table.table.mt-5.bg-white
    tr.d-none.d-md-table-row
      th.bg-secondary.text-white.p-2.text-center Projekt
      th.bg-secondary.text-white.p-2.text-center(v-for="d in days"): span(v-html='d.title.replace(",", ",<br>")')
    tr(v-for="(event, e_i) in events" v-if="events.length != 0")
      td.border.border-secondary.text-center(data-title="Projekt")
        h3.m-1 
          | {{event.start_at}} - {{event.end_at}}
          
        a.my-3(:href="'/staff/events/'+event.id+'/edit'"): svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 21px;"): path(d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" fill="currentColor")
        h5.mb-0 {{event.name}}
        p 
          svg(viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style="width: 16px; margin-top: -3px; margin-right: 3px; margin-right: 3px;")
            path(d='M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z' fill='currentColor')
          | {{event.location}}
        p
          img.rounded-circle(v-for="u in event.users" :src="u.avatar" :title="u.name" width="35" style="margin-right: 5px;")
      td.border.border-secondary(v-for="d in days" style="vertical-align: top;" :data-title="getEventsByDate(d.date, event.events).length != 0 ? d.title : ''")
        .card.border.p-0.m-1.my-3(v-for="(e, ee_i) in getEventsByDate(d.date, event.events)" :style="'border-color: '+color(e[0])+' !important;'")
          .card-header.p-1(:style="'background-color: '+color(e[0])+' !important; border-radius: 0.4rem 0.4rem 0 0 !important;'")
            small.text-white {{jobs[e[0]]}}


            svg.float-end(
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              style="cursor: pointer; width: 12px; margin-top: 2px;"
              @click="addCount(e[0], e_i, d.date)"
            )
              path(d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" fill="#fff")

            svg.float-end(
              v-if="freeSlot(e[2], e[0], parseInnerInt(e[1]))"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              style="width: 13px; margin-top: 2px; cursor: pointer"
              @click="openAgencyModal(event.start_at, event.end_at, e[4], e[0], e_i+'.'+e[5])"
            )
              path(d="M13.4 21C13.2 20.4 13 19.7 13 19C13 17.5 13.6 16.1 14.5 15H13V13H15V14.5C15.6 14 16.3 13.6 17 13.3V3H3V21H9V17.5H11V21H13.4M13 5H15V7H13V5M13 9H15V11H13V9M7 19H5V17H7V19M7 15H5V13H7V15M7 11H5V9H7V11M7 7H5V5H7V7M9 5H11V7H9V5M9 9H11V11H9V9M9 15V13H11V15H9M20 15V18H23V20H20V23H18V20H15V18H18V15H20Z" fill="#fff")

            svg.float-end(v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 13px; margin-top: 2px; opacity: 0.5;"): path(d="M13.4 21C13.2 20.4 13 19.7 13 19C13 17.5 13.6 16.1 14.5 15H13V13H15V14.5C15.6 14 16.3 13.6 17 13.3V3H3V21H9V17.5H11V21H13.4M13 5H15V7H13V5M13 9H15V11H13V9M7 19H5V17H7V19M7 15H5V13H7V15M7 11H5V9H7V11M7 7H5V5H7V7M9 5H11V7H9V5M9 9H11V11H9V9M9 15V13H11V15H9M20 15V18H23V20H20V23H18V20H15V18H18V15H20Z" fill="#fff")

            avatar.float-end(
              v-if="freeSlot(e[2], e[0], parseInnerInt(e[1]))"
              :event_id="e[4]"
              :key="freeSlot(e[2], e[0], parseInnerInt(e[1]))+e[0]+e[4]"
              :slot="freeSlot(e[2], e[0], parseInnerInt(e[1]))"
              :job="e[0]"
              :users="getUsersByJob(e[3], e[2], e[0], false, true)"
              :event_slot="getSlot(e[2], e[0], freeSlot(e[2], e[0], parseInnerInt(e[1])))"
              :jobs="jobs"
              v-model="slots[e_i+'.'+e[5]]"
              :_start_at="event.start_at"
              :_end_at="event.end_at"
              :locations="parsedLocations"
              :agencies="parsedAgencies"
              :internusers="internUsers"
              :externusers="externUsers"
              :isicon="true"
            )

            svg.float-end(v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 13px; margin-top: 2px; opacity: 0.5;"): path(d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" fill="#fff")

            a.float-end(:href="'/staff/events/'+e[4]"): svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 12px;"): path(d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" fill="#fff")


          .card-body.p-2
            avatar(
              v-for="index in parseInnerInt(e[1])"
              :event_id="e[4]"
              :key="index+e[0]+e[4]"
              :slot="index"
              :job="e[0]"
              :users="getUsersByJob(e[3], e[2], e[0], false, true)"
              :event_slot="getSlot(e[2], e[0], index)"
              :jobs="jobs"
              v-model="slots[e_i+'.'+e[5]]"
              :_start_at="event.start_at"
              :_end_at="event.end_at"
              :locations="parsedLocations"
              :agencies="parsedAgencies"
              :internusers="internUsers"
              :externusers="externUsers"
              :list="true"
            )
    tr(v-else)
      td.text-center(colspan="8")
        .text-center.my-5(v-if="loading"): .spinner-border(style="width: 150px; height: 150px;")
        h3.my-5(v-else) Keine Veranstaltung vorhanden

  .modal.fade(tabindex='-1' id="agencyModal" aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h1.modal-title.fs-5 Agentur besetzen
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          label.form-label Eingeteilter Job
          select.form-select.mb-3(v-model="agencyBatch.job_type")
            option(v-for="(value, key) in jobs" :value="key") {{value}}
          label.form-label Agentur
          v-select(:options="parsedAgencies" label="name" :clearable="false" placeholder="Agentur auswählen" v-model="agencyBatch.agency")
          .row.mt-3
            .col-12: label.form-label Einsatzzeiten
            .col-5: input.form-control(v-model="agencyBatch.start_at" type="time")
            .col-2.text-center —
            .col-5: input.form-control(v-model="agencyBatch.end_at" type="time")
          label.form-label.mt-4(for="location") Einsatzort
          v-select(:options="parsedLocations" placeholder="Einsatzort" taggable push-tags v-model="agencyBatch.location")
          label.form-label.mt-4(for="count") Anzahl
          input.form-control#count(v-model="agencyBatch.count" type="number" placeholder="Anzahl")
        .modal-footer
          button.btn.btn-secondary(type='button' data-bs-dismiss='modal') Schließen
          button.btn.btn-primary(type='button' @click="saveAgencyBatch" data-bs-dismiss='modal') Speichern

</template>

<script>
import vSelect from "vue-select";
import Avatar from "../staff_manager/Avatar.vue"
export default {
  components: {
    Avatar,
    vSelect
  },
  props: [
    "_days",
    "_jobs",
    "from",
    "to",
    "users",
    "locations",
    "agencies"
  ],
  data () {
    return {
      loading: true,
      agencyBatch: {},
      slots: {},
      events: []
    }
  },
  mounted () {
    this.fetchEvents()
  },
  watch: {
    slots: {
      handler(){
        Object.entries(this.slots).map( x => {
          this.events[x[0].split(".")[0]].events[x[0].split(".")[1]].slots = x[1]
        })
      },
      deep: true
    },
  },
  computed: {
    days(){
      return JSON.parse(this._days)
    },
    jobs(){
      return JSON.parse(this._jobs)
    },
    internUsers(){
      return this.parsedUsers.filter(x => x.employee_type == "intern")
    },
    externUsers(){
      return this.parsedUsers.filter(x => x.employee_type == "staff")
    },
    parsedLocations(){
      return JSON.parse(this.locations)
    },
    parsedAgencies(){
      return JSON.parse(this.agencies)
    },
    parsedUsers(){
      return JSON.parse(this.users)
    },
    otherUsers(){
      return this.parsedEventSlots.filter(x => x.staff_state != "yes" || !this.isCountPresent(this.parsedCounts[x.job_type]) || !x.job_type).map( x => {
        let user = this.parsedUsers.find(y => y.id == x.user_id)
        if (user) user.staff_state = x.staff_state 
        return user
      }).filter(x => x)  
    },

  },
  methods: {
    addCount(job_type, i, date){
      let event = this.events[i].events.find(x => x.date == date)
      event.staff_counts[job_type] = parseInt(event.staff_counts[job_type]) + 1

      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "PUT",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({staff_event: {staff_counts: event.staff_counts}, json: true})
      }
      fetch("/staff/events/"+event.id, options)
    },
    parseInnerInt(x){
      if (x && x.length != 0){
        return parseInt(x)
      } else{
        return 0
      }
    },
    color(job){
      return {
        event: "#3f4f4c",
        gastro: "#794044",
        logistics: "#002557"
      }[job.split("_")[0]] || "#8592a3"
    },
    openAgencyModal(start_at, end_at, event_id, job_type, slot_selector){
      this.agencyBatch = {
        id: event_id,
        job_type: job_type,
        start_at: start_at,
        end_at: end_at,
        count: 1,
        agency: null,
        slot_selector: slot_selector
      }
      this.agencyModal = new bootstrap.Modal(document.getElementById("agencyModal"))
      this.agencyModal.show()
    },
    saveAgencyBatch(){
      this.agencyBatch.agency_id = this.agencyBatch.agency.id
      this.agencyBatch.agency = null
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(this.agencyBatch)
      }
      fetch("/staff/events/slot/batch", options).then(x => x.json()).then((response) => {
        this.slots[this.agencyBatch.slot_selector] = response
      })
    },
    isCountPresent(count){
      return !!parseInt(count)
    },
    getEventsByDate(date, events){
      const event = events.find(x => x.date == date)||{}

      return Object.entries(event.staff_counts||{}).filter((x) => x[1] != "0" &&  x[1] != "").map( x => {
        x[2] = event.slots
        x[3] = event.staff_counts
        x[4] = event.id
        x[5] = events.indexOf(event)
        return x
      })
    },
    fetchEvents(){
      this.loading = true
      fetch("/api/staff/agenda?from="+this.from+"&to="+this.to).then(x => x.json()).then((data) => {
        this.loading = false
        this.events = data
      })
    },
    getUsersByJob(parsedCounts, parsedEventSlots, job, all, allJobs){
      return parsedEventSlots.filter(x => x.staff_state == "yes" || all ).filter(x => !x.slot || ((all || allJobs) && !this.isCountPresent(parsedCounts[x.job_type]))).map( x => {
        let user = this.parsedUsers.find(y => y.id == x.user_id)
        if (!user) return null
        user.job = x.job_type
        user.staff_state = x.staff_state
        user.info = x.info
        user.start_at = x.start_at
        user.end_at = x.end_at
        user.confirmation = x.confirmation
        return user
      }).filter( x => x && (all || allJobs || x.job == job) )
    },
    getSlot(parsedEventSlots, job, index){
      let slot = parsedEventSlots.find(s => s.job_type == job && s.slot == index)
      if (slot) slot.user = this.parsedUsers.find(u => u.id == slot.user_id)
      return slot
    },
    freeSlot(parsedEventSlots, job, count){
      let slots = []
      for(let i = 1; i <= count; i++){
        if (!parsedEventSlots.find(s => s.job_type == job && s.slot == i)){
          slots.push(i)
        }
      }
      return slots[0]
    }
  }
}
</script>

<style lang="scss">

</style>