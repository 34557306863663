<template lang="pug">
div
  button.btn.btn-primary.float-end(type='button' @click="openModal" style="margin-top: -70px;") {{title}} anlegen
  .card-body(v-if="elements.length == 0"): .text-center: p Es sind keine Einträge vorhanden
  .card-body(v-else): .table-responsive.text-nowrap: #no-more-tables: table.table
    thead
      tr
        th(v-for="e in ths") {{e.title}}
        th
    tbody
      tr(v-for="element in elements")
        td(v-for="e in ths" :data-title="e.title")
          span(v-if="e.type == 'number'") {{toNumber(element[e.id])}}
          span(v-else-if="e.type == 'boolean'") {{element[e.id] ? 'JA' : 'NEIN'}}
          span(v-else-if="e.type == 'select'") 
            | {{e.options[element[e.id]]||element[e.id]}}
            small(v-if="element.h1 && element.h1 != e.options[element[e.id]]"): div: i {{element.h1}}
          span(v-else) {{element[e.id]}}
          span(v-if="e.postfix") {{e.postfix(element)}}
        td.text-end: button.btn.btn-primary.btn-sm(type='button' @click="setForm(element)") bearbeiten
  .modal.fade(tabindex='-1' :id='"modal-"+id' aria-hidden='true' data-bs-backdrop="static" data-bs-keyboard="false")
    .modal-dialog.modal-xl
      .modal-content
        .modal-header
          h1.modal-title.fs-5
            span {{title}} 
            span(v-if="form.id")  bearbeiten
            span(v-else)  anlegen
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close' @click="clear")
        .modal-body
          active-form(
            :fields="fields"
            :errors="errors"
            v-model="form"
            :name="id"
            :domain="modelValue"
          )
        .modal-footer
          button.btn.btn-secondary(type='button' data-bs-dismiss='modal' @click="clear") Abrechen
          .btn-group
            button.btn.btn-primary(type='button' @click="save") Speichern
            button.btn.btn-primary.dropdown-toggle.dropdown-toggle-split(type='button' data-bs-toggle='dropdown' aria-expanded='false' v-if="form.id")
              span.visually-hidden Toggle Dropdown
            ul.dropdown-menu(v-if="form.id")
              li
                button.dropdown-item.text-danger(type='button' @click="deleteElement") {{title}} löschen
</template>


<script>
export default {
  components: {
  },
  props: [
    "id",
    "title",
    "elements",
    "fields",
    "modelValue"
  ],
  data () {
    return {
      modal: null,
      form: {},
      errors: {}
    }
  },
  mounted () {
  },
  watch: {
  },
  computed: {
    ths(){
      return this.fields.filter(x => x.th)
    }
  },
  methods: {
    toNumber(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x).replace("€", "")
    },
    openModal(){
      this.modal = new bootstrap.Modal(document.getElementById("modal-"+this.id))
      this.modal.show()
    },
    clear(){
      this.form = {}
      this.errors = {}
    },
    setForm(element){
      this.form = element
      this.openModal()
    },
    deleteElement(){
      this.form._delete = true
      this.save()
    },
    save(){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      let params = {data: {}}
      let form = JSON.parse(JSON.stringify(this.form))
      this.fields.filter(x => x.type == 'multiple').map(e => form[e.id] = form[e.id] ? form[e.id].map(x => x.id) : [])

      params.data[this.id] = [form]

      options.body = JSON.stringify(params)

      fetch(window.location.href.split("?")[0]+".json", options).then(x => x.json()).then((data) => {
        this.data = data
        if (data.errors[this.id].length == 0){
          this.modal.hide()
          this.clear()
          this.$emit('update:modelValue', data.data)
        }
        data.errors[this.id].map( e => {
          this.errors = {...e}
        })
      })
    }
  }
}
</script>

<style lang="scss">
</style>