<template lang="pug">
div
  .alert.alert-danger(v-if="errors.length != 0")
    h4.text-danger Buchung nicht möglich!
    ul
      li(v-for="e in errors") {{e}}
  .alert.alert-danger(v-if="liveErrors.length != 0")
    h4.text-danger Buchung nicht möglich!
    ul
      li(v-for="e in liveErrors") {{e}}

  .row
    .col-xl
      .card.mb-4
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Kundendaten
        .card-body
          form
            .row
              .col-12
                .mb-4
                  company-select(
                    placeholder="Kontakt suchen"
                    :nonetaggable="true"
                    :showall="true"
                    @contact="setContact"
                  )
            .row
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="salutation") Anrede *
                  select#salutation.form-select(v-model="form.salutation", placeholder="Anrede *")
                    option(:value="id" v-for="(name, id) in salutations") {{name}}
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="company") Firma
                  input#company.form-control(type="text", placeholder="Firma", v-model="form.company")
            .row
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="firstname") Vorname *
                  input#firstname.form-control(type="text", placeholder="Vorname *", v-model="form.firstname")
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="lastname") Nachname *
                  input#lastname.form-control(type="text", placeholder="Nachname *", v-model="form.lastname")
            .row
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="email") E-Mail *
                  input#email.form-control(type="email", placeholder="E-Mail *", v-model="form.email")
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="phone" v-if="dinnershow === 'true'") Telefon *
                  label.form-label(for="phone" v-else) Telefon
                  input#lastname.form-control(type="text", placeholder="Telefon", v-model="form.phone")
            .row
              .col-sm-8.col-12
                .mb-3
                  label.form-label(for="street") Straße *
                  input#street.form-control(type="text", placeholder="Straße *", v-model="form.street")
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="street_number") Haus-Nr. *
                  input#street_number.form-control(type="text", placeholder="Haus-Nr. *", v-model="form.street_number")   
                  
            .row
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="zip") PLZ *
                  input#zip.form-control(type="text", placeholder="PLZ *", v-model="form.zip")
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="city") Stadt *
                  input#city.form-control(type="text", placeholder="Stadt *", v-model="form.city") 
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="country") Land *
                  select#country.form-select(v-model="form.country", placeholder="Land *")
                    option(:value="c.id" v-for="c in parsedCountries") {{c.name}}
            .row
              .col-12
                .mb-3
                  label.form-label(for="referrer") Wie ist der Kunde auf uns aufmerksam geworden? *
                  select#referrer.form-select(v-model="form.referrer", placeholder="Wie ist der Kunde auf uns aufmerksam geworden? *")
                    option(:value="id" v-for="(name, id) in referrers") {{name}}
            .row
              .col-12
                .form-check.form-switch.mt-2.mb-3
                  input.form-check-input#has_delivery_address(type="checkbox", v-model="form.has_delivery_address")
                  label.form-check-label(for="has_delivery_address") Abweichende Lieferadresse

      .card.mb-4(v-if="form.has_delivery_address")
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Lieferadresse
        .card-body
          form
            .row
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="delivery_salutation") Anrede *
                  select#delivery_salutation.form-select(v-model="form.delivery_salutation", placeholder="Anrede *")
                    option(:value="id" v-for="(name, id) in salutations") {{name}}
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="delivery_company") Firma
                  input#delivery_company.form-control(type="text", placeholder="Firma", v-model="form.delivery_company")
            .row
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="delivery_firstname") Vorname *
                  input#delivery_firstname.form-control(type="text", placeholder="Vorname *", v-model="form.delivery_firstname")
              .col-sm-6.col-12
                .mb-3
                  label.form-label(for="delivery_lastname") Nachname *
                  input#delivery_lastname.form-control(type="text", placeholder="Nachname *", v-model="form.delivery_lastname")
            .row
              .col-sm-8.col-12
                .mb-3
                  label.form-label(for="delivery_street") Straße *
                  input#delivery_street.form-control(type="text", placeholder="Straße *", v-model="form.delivery_street")
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="delivery_street_number") Haus-Nr. *
                  input#delivery_street_number.form-control(type="text", placeholder="Haus-Nr. *", v-model="form.delivery_street_number")   
                  
            .row
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="delivery_zip") PLZ *
                  input#zip.form-control(type="text", placeholder="PLZ *", v-model="form.delivery_zip")
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="delivery_city") Stadt *
                  input#delivery_city.form-control(type="text", placeholder="Stadt *", v-model="form.delivery_city") 
              .col-sm-4.col-12
                .mb-3
                  label.form-label(for="delivery_country") Land *
                  select#delivery_country.form-select(v-model="form.delivery_country", placeholder="Land *")
                    option(:value="c.id" v-for="c in parsedCountries") {{c.name}}

    .col-xl
      .card.mb-4(v-if="voucher === 'true'")
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Gutschein Bestellung
          .btn-group.float-end
            button.btn.btn-outline-primary.btn-xs(type="button", @click="addVoucherOrderItem")
              svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 18px; height: 18px; cursor: pointer"): path(fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z")
              | Gutschein hinzufügen
        .card-body
          .mb-3(v-for="(o, i) in form.order_items")
            .repeater-wrapper.pt-0.pt-md-4
              .d-flex.border.rounded.position-relative.pe-0
                .row.w-100.m-0.p-3
                  .col-4: .mb-3
                    label.form-label(:for="'quantity_'+i") Menge *
                    input.form-control(type="number", min="0", placeholder="Menge *", v-model="o.quantity", :id="'quantity_'+i")
                  .col-8: .mb-3
                    label.form-label(:for="'product_price_'+i") Wert *
                    .input-group
                      input.form-control(type="number", min="0", placeholder="Wert *", v-model="o.product_price", :id="'product_price_'+i")
                      .input-group-text.d-none.d-sm-inline €

                .d-flex.flex-column.align-items-center.justify-content-between.border-start.p-2
                  svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; height: 24px; cursor: pointer" @click="removeOrderItem(i)"): path(fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z")



      dinnershow-ui(
        v-else-if="dinnershow === 'true'"
        :products="products"
        :apiId="apiId"
        :domainurl="domainurl"
        v-model:product="product"
        v-model:preview="previewData"
        v-model:order_item="order_item"
        v-model:innerrebook="innerRebook"
        :orderItem="orderItem"
        :domainid="domainid"
        :date="date"
        :count="count"
        :rebook="rebook"
        :editmode="editMode"
        :quota="_form.quota"
        :quota2="form.quota"
      )
      .card.mb-4(v-else)
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Bestellung
          .btn-group.float-end
            button.btn.btn-outline-primary.btn-xs(type="button", @click="addOrderItem(false)")
              svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 18px; height: 18px; cursor: pointer"): path(fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z")
              | Position hinzufügen
            button.btn.btn-outline-primary.btn-xs(type='button' @click="addOrderItem(true)")
              svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 18px; height: 18px; cursor: pointer"): path(fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z")
              | Eigenes Produkt hinzufügen
        .card-body
          .mb-3(v-for="(o, i) in form.order_items")
            .repeater-wrapper.pt-0.pt-md-4
              .d-flex.border.rounded.position-relative.pe-0
                .row.w-100.m-0.p-3
                  .col-4: .mb-3
                    label.form-label(:for="'quantity_'+i") Menge *
                    input.form-control(type="number", min="0", placeholder="Menge *", v-model="o.quantity", :id="'quantity_'+i")
                  .col-8.col-sm-6(v-if="o.product_tax"): .mb-3
                    label.form-label(:for="'product_name_'+i") Produkt *
                    input.form-control(type="text", placeholder="Produkt *", v-model="o.product_name", :id="'product_name_'+i")
                  .col-6.col-sm-2(v-if="o.product_tax"): .mb-3
                    label.form-label(:for="'product_tax_'+i") MwSt. *
                    select.form-select(v-model="o.product_tax", placeholder="MwSt." :id="'product_tax_'+i" @change="setProduct(i)")
                      option(:value="7") 7 %
                      option(:value="19") 19 %
                  .col-8(v-if="!o.product_tax"): .mb-3
                    label.form-label(:for="'selected_product_id_'+i") Produkt *
                    select.form-select(v-model="o.selected_product_id", placeholder="Produkt" :id="'selected_product_id_'+i" @change="setProduct(i)")
                      option(:value="null" disabled="") - Bitte auswählen -
                      option(:value="p.id" v-for="p in products") {{p.fullName}}
                  .col-6.col-sm-6(v-if="!o.product_name || o.product_name.indexOf('Programheft') == -1"): .mb-3
                    label.form-label(:for="'booklet_count'+i") Programmhefte
                    .input-group
                      input.form-control(type="number", min="0", placeholder="Programmheft", v-model="o.booklet_count", :id="'booklet_count'+i")
                      .input-group-text.d-none.d-sm-inline Stück
                  .col-6.col-sm-6: .mb-3
                    label.form-label(:for="'discount'+i") Rabatt
                    .input-group
                      .input-group-text -
                      input.form-control(type="number", min="0", placeholder="Rabatt", v-model="o.discount", :id="'discount'+i")
                      .input-group-text.d-none.d-sm-inline €
                  .col-6.col-sm-6: .mb-3
                    label.form-label(:for="'product_price_'+i") Preis *
                    .input-group
                      input.form-control(type="number", min="0", placeholder="Preis *", v-model="o.product_price", :id="'product_price_'+i")
                      .input-group-text.d-none.d-sm-inline €
                  .col-6.col-sm-6: .mb-3
                    label.form-label(:for="'product_price_'+i")
                    .mt-2: h5.text-center {{toCurrency(o.product_price*o.quantity-o.discount)}}
                .d-flex.flex-column.align-items-center.justify-content-between.border-start.p-2
                  svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; height: 24px; cursor: pointer" @click="removeOrderItem(i)"): path(fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z")
  .row
    .col-xl
      .card.mb-4.alert-info(v-if="parsedOrder && parsedOrder.order_items")
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Ursprungsbuchung
        .card-body(v-if="parsedOrder.order_items.length != 0")
          #no-more-tables: table.table
            thead
              tr
                th Menge
                th Name
                th Einzelpreis
                th Gesamtpreis
            tbody(v-for="(o, i) in parsedOrder.order_items")
              tr(v-if="o.product_name")
                td(data-title="Menge") {{o.quantity}}x
                td(data-title="Name") {{o.product_name}}
                td(data-title="Einzelpreis") {{toCurrency(o.price)}}
                td(data-title="Gesamtpreis") {{toCurrency(o.price*o.quantity)}}
              tr(v-if="o.discount && o.discount != 0")
                td(data-title="Menge") 1x
                td(data-title="Name") Rabatt
                td(data-title="Einzelpreis") {{toCurrency(-o.discount)}}
                td(data-title="Gesamtpreis") {{toCurrency(-o.discount)}}
              tr(v-if="o.booklet_count && o.booklet_count != 0")
                td(data-title="Menge") {{o.booklet_count}}x
                td(data-title="Name") Programmheft
                td(data-title="Einzelpreis") {{toCurrency(o.booklet_price)}}
                td(data-title="Gesamtpreis") {{toCurrency(o.booklet_price*o.booklet_count)}}
            tbody(v-for="(o, i) in parsedOrder.order_items[0].permanent_dependent_positions")
              tr(v-if="o && o.name")
                td(data-title="Menge") {{o.quantity}}x
                td(data-title="Name") {{o.name}}
                td(data-title="Einzelpreis") {{toCurrency(o.price)}}
                td(data-title="Gesamtpreis") {{toCurrency(o.price*o.quantity)}}
            tbody
              tr(v-if="form.advance_booking_fee")
                td(data-title="Menge") {{ parsedOrder.order_items[0].quantity + parsedOrder.order_items[0].ticket_count_children }}x
                td(data-title="Name") Vorverkaufsgebühr
                td(data-title="Einzelpreis") {{toCurrency(parsedOrder.advance_booking_fee)}}
                td(data-title="Gesamtpreis") {{toCurrency(parsedOrder.advance_booking_fee*(parsedOrder.order_items[0].quantity + parsedOrder.order_items[0].ticket_count_children ))}}
              tr(v-if="form.shipping_fee")
                td(data-title="Menge") 1x
                td(data-title="Name") Versandkosten
                td(data-title="Einzelpreis") {{toCurrency(parsedOrder.shipping_fee)}}
                td(data-title="Gesamtpreis") {{toCurrency(parsedOrder.shipping_fee)}}
              tr(v-if="form.discount")
                td(data-title="Menge") 1x
                td(data-title="Name") Rabatt
                td(data-title="Einzelpreis") {{toCurrency(-parsedOrder.discount)}}
                td(data-title="Gesamtpreis") {{toCurrency(-parsedOrder.discount)}}
            tfoot
              tr
                th(colspan="3") Summe
                th {{toCurrency(parsedOrder.fix_total)}}
      .card.mb-4(v-if="note && note.length != 0")
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Buchungshinweise
        .card-body(v-html="note")
      .card.mb-4
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Zusammenfassung
          button.btn.btn-xs.float-end(v-if="false && orderItems.length != 0" :class="priceEdit ? 'btn-secondary' : 'btn-outline-secondary'" type="button" @click="priceEdit=!priceEdit") Bearbeiten
        .card-body(v-if="orderItems.length != 0")
          #no-more-tables: table.table
            thead
              tr
                th Menge
                th Name
                th Einzelpreis
                th Gesamtpreis
            tbody(v-for="(o, i) in orderItems")
              tr
                td(data-title="Menge") {{o.quantity}}x
                td(data-title="Name") {{o.product_name}}
                td(data-title="Einzelpreis") 
                  input.form-control(v-if="priceEdit" v-model="o.product_price" style="width: 90px;" type="number")
                  span(v-else) {{toCurrency(o.product_price)}}
                td(data-title="Gesamtpreis") {{toCurrency(o.product_price*o.quantity)}}
              tr(v-if="o.discount && o.discount != 0")
                td(data-title="Menge") 1x
                td(data-title="Name") Rabatt
                td(data-title="Einzelpreis") {{toCurrency(-o.discount)}}
                td(data-title="Gesamtpreis") {{toCurrency(-o.discount)}}
              tr(v-if="o.booklet_count && o.booklet_count != 0")
                td(data-title="Menge") {{o.booklet_count}}x
                td(data-title="Name") Programmheft
                td(data-title="Einzelpreis") {{toCurrency(o.booklet_price)}}
                td(data-title="Gesamtpreis") {{toCurrency(o.booklet_price*o.booklet_count)}}
            tbody(v-for="(o, i) in previewData.dependent_positions" v-if="previewData")
              tr(v-if="o && o.name")
                td(data-title="Menge") {{o.quantity}}x
                td(data-title="Name") {{o.name}}
                td(data-title="Einzelpreis")
                  input.form-control(v-if="priceEdit" v-model="o.price" style="width: 90px;" type="number")
                  span(v-else) {{toCurrency(o.price)}}
                td(data-title="Gesamtpreis") {{toCurrency(o.price*o.quantity)}}
            tbody
              tr(v-if="form.advance_booking_fee")
                td(data-title="Menge") {{ advanceBookingCount }}x
                td(data-title="Name") Vorverkaufsgebühr
                td(data-title="Einzelpreis") {{toCurrency(form.advance_booking_fee)}}
                td(data-title="Gesamtpreis") {{toCurrency(form.advance_booking_fee*advanceBookingCount)}}
              tr(v-if="form.shipping_fee")
                td(data-title="Menge") 1x
                td(data-title="Name") Versandkosten
                td(data-title="Einzelpreis") {{toCurrency(form.shipping_fee)}}
                td(data-title="Gesamtpreis") {{toCurrency(form.shipping_fee)}}
              tr(v-if="form.discount")
                td(data-title="Menge") 1x
                td(data-title="Name") Rabatt
                td(data-title="Einzelpreis") {{toCurrency(-form.discount)}}
                td(data-title="Gesamtpreis") {{toCurrency(-form.discount)}}
            tbody(v-if="discountAmount")
              tr
                th(colspan="3") Zwischen Summe
                th {{toCurrency(preOrderSum)}}
              tr
                td(data-title="Menge") 1x
                td(data-title="Name") Gutschein
                td(data-title="Einzelpreis") {{toCurrency(-discountAmount)}}
                td(data-title="Gesamtpreis") {{toCurrency(-discountAmount)}}
              tr
                td(data-title="Menge") 1x
                td(data-title="Name") Restwert Gutschein
                td(data-title="Einzelpreis") {{toCurrency(residualDiscount)}}
                td(data-title="Gesamtpreis") {{toCurrency(residualDiscount)}}
            tfoot
              tr
                th(colspan="3") Summe
                th {{toCurrency(orderSum)}}

        .card-body(v-else)
          .text-center: small Keine Produkte vorhanden
    .col-xl
      .card.mb-4
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Buchung - {{domainname}}
        .card-body
          .row
            .col-sm.col-12(v-if="voucher != 'true'")
              .mb-3
                label.form-label(for="advance_booking_fee") Vorverkaufsgebühr
                .input-group
                  input#advance_booking_fee.form-control(type="number", min="0", placeholder="Vorverkaufsgebühr", v-model="form.advance_booking_fee")
                  .input-group-text €
            .col-sm.col-12(v-if="dinnershow != 'true' || voucher === 'true'")
              .mb-3
                label.form-label(for="shipping_fee") Versandkosten
                .input-group
                  input#shipping_fee.form-control(type="number", min="0", placeholder="Vorverkaufsgebühr", v-model="form.shipping_fee")
                  .input-group-text €
            .col-sm.col-12
              .mb-3
                label.form-label(for="assigned_user_id") Beteiligter Mitarbeiter
                select#assigned_user_id.form-select(v-model="form.assigned_user_id", placeholder="Beteiligter Mitarbeiter")
                  option(:value="u.id" v-for="u in parsedUsers") {{u.name}}
          .row
            .col-6
              .mb-3
                label.form-label(for="paymentMethod") Zahlungsmethode
                select#paymentMethod.form-select(v-model="form.intern_payment_method", placeholder="Zahlungsmethode")
                  option(:value="id" v-for="(name, id) in paymentMethods") {{name}}
            .col-6
              .mb-3
                label.form-label(for="disableCustomerEmail") Email an Kunden
                select#disableCustomerEmail.form-select(v-model="form.disable_customer_email", placeholder="Email an Kunden")
                  option(:value="id" v-for="(name, id) in disableCustomerEmailOptions") {{name}}
          .row
            .col-12(v-if="form.intern_payment_method == 'card'")
              label.form-label(for="own_transaction_id") Transaktions-Id
              input#own_transaction_id.form-control(type="text", placeholder="Transaktions-Id", v-model="form.own_transaction_id")
          .row
            .col-6.mb-3
              label.form-label(for="discount_code") Gutscheincode
              .input-group
                input.form-control(name='discount_code' placeholder='Gutscheincode' type='text' v-model="form.discount_code")
                button.btn.btn-primary(type='button' @click="checkDiscountCode")
                  svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:18px"): path(fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z")
              .text-danger(v-if="discountError") Gutscheincode ist nicht gültig!
              button.btn.btn-link.btn-sm.m-0.p-0.pt-1(type='button' data-bs-toggle='modal' data-bs-target='#voucherModal'): small Mehrere Gutscheincodes verwenden
              #voucherModal.modal.fade(tabindex='-1' aria-hidden='true')
                .modal-dialog
                  .modal-content
                    .modal-header
                      h1.modal-title.fs-5 Mehrere Gutscheincodes verwenden
                      button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
                    .modal-body
                      p Mehrere Gutscheincodes können verwendet werden, wenn diese vorher vereint werden. Dazu bitte Gutscheincodes angeben. Nach der Vereinigung werden diese entwertet und ein neuer Sammelgutschein angelegt.
                      .mt-3(v-for="c in codes")
                        label.form-label Gutscheincode
                        .input-group
                          input.form-control(name='discount_code' placeholder='Gutscheincode' type='text' v-model="c.code")
                          button.btn.btn-primary(type='button' @click="checkCode(c)")
                            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:18px"): path(fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z")
                        .text-danger(v-if="c.discountError") Gutscheincode ist nicht gültig!                       
                        .text-success(v-if="c.discountAmount") Gutscheinwert: {{toCurrency(c.discountAmount)}}                      
                    .modal-footer.mt-5
                      button.btn.btn-secondary(type='button' data-bs-dismiss='modal') Abbrechen
                      button.btn.btn-primary(type='button' data-bs-dismiss='modal' @click="mergeCodes") Vereinigen
            .col-6.mb-3
              label.form-label(for="discount_code2") Rabattcode
              .input-group
                input.form-control(name='discount_code2' placeholder='Rabattcode' type='text' v-model="form.discount_code2")
                button.btn.btn-primary(type='button' @click="checkDiscountCode2")
                  svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:18px"): path(fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z")
              div(v-if="discountCodePayload" :class="discountCodePayload[2]") {{discountCodePayload[1]}}
          .row
            .col-12(v-if="form.intern_payment_method == 'invoice'")
              label.form-label(for="invoice_date") Zahlungsziel
              input#invoice_date.form-control(type="date", placeholder="Zahlungsziel", v-model="form.invoice_date")
            .col-12.my-3
              label.form-label(for="note") Bemerkung Intern
              textarea.form-control#note(v-model="form.note" placeholder="Bemerkung Intern")
            .col-12(v-if="voucher != 'true' && (!editMode || _form.quota)")
              .form-check.form-switch.mt-2.mb-3
                input.form-check-input#quota(type="checkbox", v-model="form.quota")
                label.form-check-label(for="quota") Als Kontigent buchen
          .row
            .col-12.mt-3
              .d-grid: button.btn.btn-primary.btn-lg(type="button" @click="submit" :class="{disabled: loading || (previewData && previewData.errors.length != 0)} ")
                span.spinner-border.spinner-border-sm(style="margin-right: 10px;" v-if="loading")
                span JETZT BUCHEN
              .d-grid(v-if="dinnershow === 'true' && voucher != 'true'"): button.btn.btn-outline-primary.btn-lg.mt-4(type="button" @click="downloadOffer" :class="{disabled: loading} ")
                span.spinner-border.spinner-border-sm(style="margin-right: 10px;" v-if="loading")
                span Unverbindliches Angebot herunterladen

</template>

<script>
import fileDownload from 'js-file-download';
import DinnershowUi from './DinnershowUi.vue'
import CompanySelect from '../company_select/App.vue'
export default {
  components: {
    DinnershowUi,
    CompanySelect
  },
  props: [
    "countries",
    "users",
    "internproducts",
    "contact",
    "domainname",
    "domainid",
    "domainurl",
    "dinnershow",
    "order",
    "date",
    "count",
    "note",
    "voucher"
  ],
  data () {
    return {
      codes: [{code: ""}],
      innerRebook: false,
      rebook: false,
      discountAmount: 0,
      discountError: false,
      priceEdit: false,
      order_item: null,
      orderItem: {},
      apiId: "AEYHCbVAZtaK4VWM3R8ytqJAAvnRCe6FNTSk",
      previewData: null,
      loading: false,
      liveErrors: [],
      errors: [],
      products: [],
      product: null,
      discountCodePayload: null,
      salutations: {
        male: "Herr",
        female: "Frau",
        other: "Neutral"
      },
      referrers: {
        social_media: "Soziale Medien",
        flyer: "Flyer / Plakate",
        radio: "Radio",
        website: "Website / Internet",
        print: "Zeitung / Presse",
        recommendation: "Empfehlung",
        previous_visit: "vorheriger Besuch",
        other_referrer: "Sonstiges"
      },
      paymentMethods: {
        invoice: "Auf Rechnung",
        card: "Kartenzahlung",
        bar: "Bar",
        raffle: "Verlosung",
        invitation: "Einladung"
      },
      disableCustomerEmailOptions: {
        false: "Kunde erhält Email",
        true: "Kunde erhält KEINE Email",
      },
      _form: {},
      form: {
        has_delivery_address: false,
        delivery_salutation: "male",
        delivery_firstname: "",
        delivery_lastname: "",
        delivery_company: "",
        delivery_street: "",
        delivery_street_number: "",
        delivery_zip: "",
        delivery_city: "",
        delivery_country: "DE",
        contact_id: null,
        assigned_user_id: null,
        advance_booking_fee: 5,
        shipping_fee: 0,
        company: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        street: "",
        street_number: "",
        zip: "",
        city: "",
        intern_payment_method: "invoice",
        referrer: "other_referrer",
        salutation: "male",
        country: "DE",
        note: "",
        order_items: [],
        own_transaction_id: "",
        quota: false,
        invoice_date: new Date().toISOString().split("T")[0],
        disable_customer_email: false,
        discount_code: "",
        discount_code2: ""
      }
    }
  },
  mounted () {
    if (this.dinnershow == "true") this.form.advance_booking_fee = 4.5
    const now = new Date();
    const next30Days = now.getTime() + 14 * 24 * 60 * 60 * 1000;
    this.form.invoice_date = new Date(next30Days).toISOString().split("T")[0];

    if (this.parsedOrder){
      Object.keys(this.form).map( x => {
        this.form[x] = this.parsedOrder[x]
      })
    }

    if (this.voucher === 'true'){
      this.form.advance_booking_fee = 0
      this.form.shipping_fee = 3.90

      this.addVoucherOrderItem()
    } else {
      this.fetchTickets()
      this.addOrderItem()
    }
    if (this.contact) this.setContact(JSON.parse(this.contact))
    
    this._form = JSON.parse(JSON.stringify(this.form))
  },
  watch: {
    form: {
      handler(){
        if (!this.editMode) return false
        const rebookValues = ["advance_booking_fee", "shipping_fee", "company", "firstname", "lastname", "street", "street_number", "zip", "city", "intern_payment_method", "salutation", "country", "discount_code", "discount_code2", "invoice_date", "quota"]
        if (rebookValues.map(x => this.form[x] != this._form[x]).indexOf(true) != -1){
          this.rebook = true
        } else {
          this.rebook = false
        }
      },
      deep: true
    },
    previewData: {
      handler(){
        this.setLiveErrors()
      },
      deep: true
    },
    product: {
      handler(){
        console.log(this.product)
        const pre7Days = new Date(Date.parse(this.product.date)).getTime() - 7 * 24 * 60 * 60 * 1000;
        if (pre7Days < Date.parse(this.form.invoice_date)) this.form.invoice_date = new Date(pre7Days).toISOString().split("T")[0];
      },
      deep: true
    },
    order_item: {
      handler(){
        if (!this.order_item.date && this.parsedOrder){
          Object.keys(this.order_item).map( x => {
            this.orderItem[x] = this.parsedOrder.order_items[0][x]
          })
        }
        this.form.order_items = [this.order_item]
      },
      deep: true
    }
  },
  computed: {
    editMode(){
      return !!this.parsedOrder && !!this.parsedOrder.token
    },
    advanceBookingCount(){
      return this.orderQuantity - this.orderItems.filter(x => x.product_name.indexOf("Programheft") != -1).map(x => x.quantity).reduce((a, b) => a + b, 0)
    },
    orderItems(){
      return this.form.order_items.filter(x => x.product_name)
    },
    orderQuantity(){
      return this.orderItems.map(x => {
        if (this.dinnershow === 'true' && this.voucher != 'true'){
          return x.quantity + this.form.order_items[0].ticket_count_children
        } else {
          return x.quantity
        }
      }).reduce((a, b) => a + b, 0)
    },
    residualDiscount(){
      if (this.preOrderSum - this.discountAmount < 0) {
        return (this.preOrderSum - this.discountAmount) * -1

      } else {
        return 0
      }
    },
    preOrderSum(){
      let base = this.orderItems.map(x => (x.quantity*x.product_price)+((x.booklet_count||0)*(x.booklet_price||0))-x.discount).reduce((a, b) => a + b, 0) + (this.advanceBookingCount * this.form.advance_booking_fee) + this.form.shipping_fee

      if (this.previewData && this.previewData.dependent_positions ) base += this.previewData.dependent_positions.map(x => x.quantity*x.price).reduce((a, b) => a + b, 0)
      return base
    },
    orderSum(){
      return this.preOrderSum - this.discountAmount + this.residualDiscount
    },

    parsedCountries(){
      return JSON.parse(this.countries)
    },
    parsedUsers(){
      return JSON.parse(this.users)
    },
    parsedOrder(){
      if (this.order) return JSON.parse(this.order)
    }
  },
  methods: {
    mergeCodes(){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({codes: this.codes, id: this.domainid})
      }

      fetch("/ticketshops/vouchers/merge.json", options).then(x => x.json()).then((data) => {
        this.form.discount_code = data.code
        this.checkDiscountCode()
        this.codes = [{code: ""}]
      })
    },
    checkCode(c){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({token: this.apiId, code: c.code})
      }
      fetch(this.domainurl+"/api/voucher.json", options).then(x => x.json()).then((data) => {
        c.discountError = !data.present
        c.discountAmount = data.amount

        if (data.present){
          this.codes.push({code: ""})
        }
      })
    },
    setContact(contact){
      this.form.company = ""
      this.form.firstname = ""
      this.form.lastname = ""
      this.form.email = ""
      this.form.phone = ""
      this.form.street = ""
      this.form.street_number = ""
      this.form.zip = ""
      this.form.city = ""
      this.form.intern_payment_method = "invoice"
      this.form.referrer = "other_referrer"
      this.form.salutation = "male"
      this.form.country = "DE"
      Object.keys(this.form).map( x => {
        if (contact[x] && contact[x].length != 0) {
          this.form[x] = contact[x]
          if (this.form["delivery_"+x] || this.form["delivery_"+x] == "") this.form["delivery_"+x] = contact[x]
        }
      })
      if (!this.form.phone && this.form.phone.length === 0)  this.form.phone = contact.mobile || ""
    },
    setLiveErrors(){
      setTimeout(() => {
        this.liveErrors = this.previewData.errors
      }, 300);
    },
    downloadOffer(){
      this.loading = true
      this.form.domain_id = this.domainid
      this.form.fix_total = this.orderSum
      this.form.advance_booking_fee = this.form.advance_booking_fee && this.form.advance_booking_fee.toString().length != 0 ? this.form.advance_booking_fee : 0
      this.form.shipping_fee = this.form.shipping_fee && this.form.shipping_fee.toString().length != 0 ? this.form.shipping_fee : 0

      this.form.order_items = this.form.order_items.map( item => {
        if (item.selected_product_id) item.product_id = item.selected_product_id.toString().split("-")[0]
        return item
      })
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      let form = JSON.parse(JSON.stringify(this.form))
      if (this.previewData && this.previewData.dependent_positions){
        form.order_items[0].permanent_dependent_positions = this.previewData.dependent_positions
      }
      options.body = JSON.stringify({data: form, product: this.product})
      fetch("/ticketshops/orders/offer/download", options).then(response => {
        this.loading = false
        const filename = response.headers.get('content-disposition')
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .replaceAll('"', '')
          .trim()
          response.blob().then(blob => {
            fileDownload(blob, filename)

          })
      })     
    },
    submit(){
      this.loading = true
      if (this.parsedOrder) this.form.order_token = this.parsedOrder.token
      this.form.domain_id = this.domainid
      this.form.fix_total = this.orderSum

      this.form.advance_booking_fee = this.form.advance_booking_fee && this.form.advance_booking_fee.toString().length != 0 ? this.form.advance_booking_fee : 0
      this.form.shipping_fee = this.form.shipping_fee && this.form.shipping_fee.toString().length != 0 ? this.form.shipping_fee : 0

      this.form.order_items = this.form.order_items.map( item => {
        if (item.selected_product_id) item.product_id = item.selected_product_id.toString().split("-")[0]
        return item
      })

      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      let form = JSON.parse(JSON.stringify(this.form))
      if (this.previewData && this.previewData.dependent_positions){
        form.order_items[0].permanent_dependent_positions = this.previewData.dependent_positions
      }

      if (this.innerRebook) form.rebook = true

      options.body = JSON.stringify({data: form})

      fetch(window.location.href.split("?")[0]+".json", options).then(x => x.json()).then((data) => {
        this.loading = false
        if (data.errors) this.errors = data.errors
        this.setLiveErrors()
        if (data.path){
          window.location.href = data.path
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      })
    },
    removeOrderItem(i){
      this.form.order_items = this.form.order_items.filter((_x, x) => {
        return i != x
      })
    },
    addVoucherOrderItem(){
      this.form.order_items.push({
        quantity: 1, 
        product_price: 100,
        discount: 0,
        booklet_count: 0,
        product_name: "Gutschein",
        product_tax: 0,
        create_voucher: true
      })
    },
    addOrderItem(empty_product){
      let params = {selected_product_id: null, quantity: 1, product_price: 0, discount: 0, booklet_count: 0}
      if (empty_product){
        params.product_name = ""
        params.product_tax = 7
      }
      this.form.order_items.push(params)
    },
    setProduct(i){
      const id = this.form.order_items[i].selected_product_id
      if (!id) return false
      const product = this.products.find( x => x.id === id)
      this.form.order_items[i].product_price = product.price
      this.form.order_items[i].product_name = product.name
      this.form.order_items[i].booklet_price = product.booklet_price

      const pre7Days = new Date(Date.parse(product.date)).getTime() - 7 * 24 * 60 * 60 * 1000;
      if (pre7Days < Date.parse(this.form.invoice_date)) this.form.invoice_date = new Date(pre7Days).toISOString().split("T")[0];
    },
    checkDiscountCode2(){
      const order_items = JSON.parse(JSON.stringify(this.form.order_items)).map( item => {
        if (item.selected_product_id) item.product_id = item.selected_product_id.toString().split("-")[0]
        item.price = item.product_price
        delete item.product_price
        return item
      })
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({token: this.apiId, code: this.form.discount_code2, order_items: order_items})
      }


      fetch(this.domainurl+"/api/discount.json", options).then(x => x.json()).then((data) => {
        this.discountCodePayload = data
        this.form.order_items[0].discount = data[0]
      })
    },
    checkDiscountCode(){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({token: this.apiId, code: this.form.discount_code})
      }
      fetch(this.domainurl+"/api/voucher.json", options).then(x => x.json()).then((data) => {
        this.discountError = !data.present
        this.discountAmount = data.amount
      })
    },
    fetchTickets(){
      fetch(this.domainurl+"/api/products.json?token="+this.apiId).then(x => x.json()).then((data) => {
        this.products = [...data, ...JSON.parse(this.internproducts)]
      })
    },
    toCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    }
  }
}
</script>

<style lang="scss">
</style>