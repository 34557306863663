<template lang="pug">
.m-1
  .row.border.border-primary
     .col-12.text-center(:style="{backgroundColor: table.color}"): strong {{number}}
  
  draggable(
    v-for="(e, i) in tableTickets"
    v-model="tableTickets[i]"
    class="row border border-primary"
    group="number"
    item-key="id"
    :sort="false"
    :move="checkMove"
    @end="endMove"
    ghost-class="ghost"
    :draggable="read_only=='false' ? '.item' : '.no-drop-item'"
  )
    template(#item="{element}")
      .col-6.m-0.person.text-center.item(
        v-if="element.name && !isBlock(element)"
        :style="{backgroundColor: bgColor(element), color: textColor(element)}"
        :data-col="element.i"
        :data-table="element.table_number"
        @click="action(element)"
        data-block="true"
        :data-category="table.category"
        @mouseover="mouseover(element.customer_number)"
        @mouseleave="mouseover()"
        :class="isBlock(element) ? 'block' : ''"
      ) {{element.name.replaceAll(" ", "").substring(0,3)}}
      .col-6.m-0.person.border.border-primary.ghost.text-center(
        v-else
        :data-col="element.i"
        :data-table="element.table_number"
        :style="{cursor: 'not-allowed', color: textColor(element)}"
        :data-category="table.category"
        :class="(isBlock(element) ? 'block' : '') + ' ' + (element.backup ? 'backup' : '')"
        :data-block="isBlock(element) ? 'true' : null"
      )
        span(v-if="element.name") {{element.name.replaceAll(" ", "").substring(0,3)}}

</template>

<script>
import Draggable from 'vuedraggable/src/vuedraggable';
export default {
  components: {
    Draggable
  },
  props: [
    "number",
    "data",
    "horizontal",
    "mirrored",
    "read_only"
  ],
  data () {
    return {
      personCount: "",
      wishTables: "",
      tickets: ["a", "b", "c"]
    }
  },
  mounted () {

  },
  watch: {
    data(){
    }
  },
  computed: {
    tableTickets: {
        get() {
          let ticketArray = [];
          let backupArray = [];
          [...Array(Math.ceil((this.table.size||0) / 2))].map((item, i) => {
            ticketArray.push({...this.data.tickets.find( t => t.table_number == this.number && t.col_number == this.colBuilder(i, 1, this.table.size)), class: "text-right", i: this.colBuilder(i, 1, this.table.size), table_number: this.number})
            ticketArray.push({...this.data.tickets.find( t => t.table_number == this.number && t.col_number == this.colBuilder(i, 2, this.table.size)), class: "text-left", i: this.colBuilder(i, 2, this.table.size), table_number: this.number})
          });

          [...Array(Math.ceil((this.table.backup||0) / 2))].map((item, i) => {
            backupArray.push({...this.data.tickets.find( t => t.table_number == this.number && t.col_number == this.colBuilder(i, 1, this.table.backup) + this.table.size), class: "text-right", i: this.colBuilder(i, 1, this.table.backup) + this.table.size, table_number: this.number, backup: true})
            backupArray.push({...this.data.tickets.find( t => t.table_number == this.number && t.col_number == this.colBuilder(i, 2, this.table.backup) + this.table.size), class: "text-left", i: this.colBuilder(i, 2, this.table.backup) + this.table.size, table_number: this.number, backup: true})
          });

          return [ticketArray, backupArray]
        },
        set(value) {
        }
    },
    tableNumber(){
      if (this.number == 1 || this.number == 2){
        return "B" + this.number
      }else{
        return this.number
      }
    },
    table(){
      const table = this.data.tables[parseInt(this.number)]
      if (table){
        return table
      }else{
        console.log("No Table found: " + this.number)
        return {}
      }
    }
  },
  methods: {
    textColor(e){
      if (!e.fixed_category) return "#697a8d"
      if (!e.category) return "#697a8d"

      let fixed_category = parseInt(e.fixed_category.replace("K", "")) || 0
      if (fixed_category == "BT") fixed_category = 0

      let category = parseInt(e.category.replace("K", "")) || 0
      if (category == "BT") category = 0

      if (fixed_category < category){
        return "#ff0000"
      } else if (fixed_category > category){
        return "#1dc143"
      }

      return "#697a8d"
    },
    isBlock(e){
      return this.table.block_cols && this.table.block_cols.indexOf(e.i) != -1
    },
    mouseover(customer_number){
      if(!this.data.hoverMode) return false
      this.$emit("action", {action: "hover", customer_number: customer_number})
    },
    colBuilder(i, mode, size){
     if (this.mirrored === true){
        if (mode === 1){
          return size-i
        }else{
          return Math.ceil(size / 2)-i
        }
      } else if (this.horizontal === true ){
        if (mode === 1){
          return size-i
        }else{
          return i+1
        }
      } else {
        if (mode === 1){
          return i+1
        }else{
          return Math.ceil(size / 2)+i+1
        }
      }
    },
    endMove(evt){
      const to = evt.originalEvent.target.dataset
      if (to.block) return false
      const from = evt.item.dataset
      this.$emit("action", {action: "move", to: to, from: from})
    },
    checkMove(evt){
      return false
    },
    action(element){
      this.$emit("action", {action: "modal", ticket: element})
    },
    bgColor(ticket){
      const searchFields = ["name", "customer_number", "order_number", "email"]
      if (this.data.search && searchFields.map((x) => ticket && ticket[x] && String(ticket[x]).toLowerCase().indexOf(this.data.search.toLowerCase()) != -1).indexOf(true) != -1 ) {
        return "#fcee83"
      } else if (this.data.hoverMode && this.data.customerNumberHover == ticket.customer_number ) {
        if (ticket.vegan){
          return "#bff780"
        }else{
          return "#fcee83"
        }
      }else if (ticket.vegan){
        return "#99ddc1"
      }
    },
  }
}
</script>

<style lang="scss">
.person{
  cursor: pointer;
  height: 20px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.backup{
  opacity: 0.5;
  background: #92a9ef;
}
.block {
  opacity: 0.5;
  background: #000000;
  cursor: not-allowed;
}

</style>