<template lang="pug">
div
  svg.float-end(v-if="isicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-bs-toggle='modal' :data-bs-target='"#"+id' style="width: 13px; margin-top: 2px; cursor: pointer;"): path(d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" fill="#fff")
  div(v-else)
    div(v-if="small_user")
      strong(v-if="small_user.staff_state == 'yes'" style="margin-right: 15px; cursor: pointer;" :class="'text-'+variant" data-bs-toggle='modal' :data-bs-target='"#"+id')
        img.rounded-circle.border.border-2(:src="small_user.avatar" style="width: 20px; margin-right: 5px;" :class="'border-'+variant")
        | {{small_user.name}}
      strong(v-else style="margin-right: 15px;" :class="'text-'+variant")
        img.rounded-circle.border.border-2(:src="small_user.avatar" style="width: 20px; margin-right: 5px;" :class="'border-'+variant")
        | {{small_user.name}}
    .w-100(v-else-if="list" data-bs-toggle='modal' :data-bs-target='"#"+id' style="cursor: pointer;")
      .float-start.avatar.mt-1(style="width: 35px;height: 35px;")
        img.rounded-circle.border.border-3(:class="'border-'+variant" :src="agency.avatar" v-if="agency && agency.avatar")
        img.rounded-circle.border.border-3(:class="'border-'+variant" :src="internUser.avatar" v-else-if="internUser && internUser.avatar")
        img.rounded-circle.border.border-3(:class="'border-'+variant" :src="person.avatar" v-else-if="person && person.avatar")
        span.avatar-initial.rounded-circle.bg-label-primary(v-else) ?
        span.badge.rounded-pill(v-if="person || agency || internUser" style="position: absolute; right: -5px; padding: 4px;" :class="'bg-'+variant"): svg(style="width: 8px;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"): path(:d="icon" fill="currentColor") 
      div(style="position: relative;left: 8px;")
        .name-cut.fw-bold(v-if="person") {{person.name}}
        .name-cut.fw-bold(v-else-if="agency") {{agency.name}}
        .name-cut.fw-bold(v-else-if="internUser") {{internUser.name}}
        .name-cut.fw-bold(v-else style="position: relative;top: 8px;") Nicht besetzt
        .name-cut(v-if="!disablebottom && (person || internUser|| agency) && start_at && end_at" style=" margin-top: -5px;"): small {{start_at}} - {{end_at}}
      .clearfix
    div(v-else)
      div.text-center(v-if="person" style="width: 85px;"): strong {{person.name}}
      div.text-center(v-else-if="agency" style="width: 85px;"): strong {{agency.name}}
      div.text-center(v-else-if="internUser" style="width: 85px;"): strong {{internUser.name}}
      .avatar.avatar-xl.m-2(data-bs-toggle='modal' :data-bs-target='"#"+id')
        img.rounded-circle.border.border-3(:class="'border-'+variant" :src="agency.avatar" v-if="agency && agency.avatar")
        img.rounded-circle.border.border-3(:class="'border-'+variant" :src="internUser.avatar" v-else-if="internUser && internUser.avatar")
        img.rounded-circle.border.border-3(:class="'border-'+variant" :src="person.avatar" v-else-if="person && person.avatar")
        span.avatar-initial.rounded-circle.bg-label-primary(v-else) ?
        span.badge.rounded-pill(v-if="person || agency || internUser" style="position: absolute; right: -5px; padding: 4px;" :class="'bg-'+variant"): svg(style="width: 17px;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"): path(:d="icon" fill="currentColor")
      div.text-center(style="width: 85px;" v-if="!disablebottom && (person || internUser|| agency) && start_at && end_at"): small {{start_at}} - {{end_at}}
      div.text-center(style="width: 85px;" v-if="!disablebottom && (person || internUser || agency) && location"): small {{location}}
  .modal.fade(tabindex='-1' :id="id" aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h1.modal-title.fs-5 Besetzung bearbeiten
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          label.form-label Eingeteilter Job
          select.form-select.mb-3(v-model="innerJob")
            option(v-for="(value, key) in jobs" :value="key") {{value}}
          label.form-label Art der Besetzung
          select.form-select.mb-3(v-model="inviteMode")
            option(v-for="(value, key) in inviteModes" :value="key") {{value}}
          div(v-if="inviteMode === 'agency'")
            label.form-label Agentur
            v-select(:options="agencies" label="name" :clearable="false" placeholder="Agentur auswählen" v-model="preAgency")
          div(v-else-if="inviteMode === 'internUser' || inviteMode === 'externUser'")
            label.form-label Mitarbeiter
            v-select(:options="inviteMode === 'internUser' ? internusers : externusers" label="name" :clearable="false" placeholder="Mitarbeiter auswählen" v-model="preInternUser")
              template(v-slot:option="option")
                img.rounded-circle.select-avatar(:src="option.avatar")
                | {{ option.name }}
              template(#selected-option="option")
                img.rounded-circle.select-avatar(:src="option.avatar" style="margin-left: -2px;")
                | {{ option.name }}
              template(#no-options="{ search, searching, loading }") Keine Person gefunden.
            div(v-if="preInternUser && preInternUser.favourite_jobs_title")  {{preInternUser.favourite_jobs_title}}
          div(v-else-if="inviteMode === 'user'")
            label.form-label Person
            v-select(:options="persons" label="name" :clearable="false" placeholder="Person aus Verfügbarkeit auswählen" v-model="prePerson")
              template(v-slot:option="option")
                img.rounded-circle.select-avatar(:src="option.avatar")
                | {{ option.name }}
              template(#selected-option="option")
                img.rounded-circle.select-avatar(:src="option.avatar" style="margin-left: -2px;")
                | {{ option.name }}
              template(#no-options="{ search, searching, loading }") Keine Person gefunden.
            i(v-if="event_slot"): small.text-danger {{event_slot.info}}
            div(v-if="prePerson && prePerson.favourite_jobs_title") {{prePerson.favourite_jobs_title}}
          .row.mt-3
            .col-12: label.form-label Einsatzzeiten
            .col-5: input.form-control(v-model="start_at" type="time")
            .col-2.text-center —
            .col-5: input.form-control(v-model="end_at" type="time")
          
          label.form-label.mt-4(for="location") Einsatzort
          v-select(:options="locations" placeholder="Einsatzort" taggable push-tags v-model="prelocation")
          .form-check.form-switch.mt-4
            input.form-check-input(type='checkbox' role='switch' v-model="confirmation" :id="'confirmation'+id")
            label.form-check-label(:for="'confirmation'+id") Einsatz bestätigen
        .modal-footer
          button.btn.btn-secondary(type='button' data-bs-dismiss='modal') Schließen
          .btn-group.dropup
            button.btn.btn-primary(type='button' @click="() => save()" data-bs-dismiss='modal') Speichern
            button.btn.btn-primary.dropdown-toggle.dropdown-toggle-split(type='button' data-bs-toggle='dropdown' aria-expanded='false')
              span.visually-hidden Toggle Dropdown
            ul.dropdown-menu
              li
                button.dropdown-item.text-danger(type='button' @click="deleteElement" data-bs-dismiss='modal') Besetzung löschen


</template>
<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect
  },
  props: [
    "small_user",
    "event_id",
    "slot",
    "list",
    "isicon",
    "job",
    "jobs",
    "users",
    "event_slot",
    "_start_at",
    "_end_at",
    "locations",
    "agencies",
    "disablebottom",
    "internusers",
    "externusers",
  ],
  data () {
    return {
      inviteModes: {
        user: "Person aus Verfügbarkeit",
        agency: "Agentur",
        internUser: "Mitarbeiter",
        externUser: "Freie Mitarbeiter"
      },
      changedPerson: false,
      person: null,
      prePerson: null,
      start_at: null,
      end_at: null,
      confirmation: false,
      innerJob: null,
      prelocation: null,
      location: null,
      preAgency: null,
      agency: null,
      preInternUser: null,
      internUser: null,      
      inviteMode: "user"
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    event_slot(){
      this.init()
    },
    small_user(){
      this.init()
    }
  },
  computed: {
    id(){
      return this.job+this.event_id+this.slot+((this.small_user||{}).username)
    },
    icon(){
      if (!this.event_slot || !this.event_slot.confirmation){
        return "M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z"
      }else {
        return "M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
      }
    },
    variant(){
      if (this.small_user){
        if (this.small_user.staff_state == "pending"){
          return "secondary"
        }else if (this.small_user.staff_state == "yes"){
          return "success"
        }else if (this.small_user.staff_state == "no"){
          return "danger"
        }else if (this.small_user.staff_state == "maybe"){
          return "warning"
        }
      } else {
        if (!this.event_slot || !this.event_slot.confirmation){
          return "secondary"
        }else {
          return "success"
        }
      }
    },
    persons(){
      return this.users
    }
  },
  methods: {
    init(){
      this.innerJob = this.job
      if (this.event_slot){
        this.person = this.event_slot.user
        this.prelocation = this.event_slot.location
        this.location = this.event_slot.location
        this.prePerson = this.event_slot.user
        if (this.event_slot.start_at) this.start_at = this.event_slot.start_at.split("T")[1].split(":00.000")[0]
        if (this.event_slot.end_at) this.end_at = this.event_slot.end_at.split("T")[1].split(":00.000")[0]
        this.confirmation = this.event_slot.confirmation

        if (this.event_slot.agency_id){
          this.inviteMode = "agency"
        }else if (this.event_slot.intern_user_id && this.internusers.find(x => x.id === this.event_slot.intern_user_id) ){
          this.inviteMode = "internUser"
        }else if (this.event_slot.intern_user_id && this.externusers.find(x => x.id === this.event_slot.intern_user_id) ){
          this.inviteMode = "externUser"
        }else {
          this.inviteMode = "user"
        }

        this.preAgency = this.agencies.find(x => x.id === this.event_slot.agency_id)
        this.agency = this.agencies.find(x => x.id === this.event_slot.agency_id)

        this.preInternUser = this.internusers.find(x => x.id === this.event_slot.intern_user_id) || this.externusers.find(x => x.id === this.event_slot.intern_user_id)
        this.internUser = this.internusers.find(x => x.id === this.event_slot.intern_user_id) || this.externusers.find(x => x.id === this.event_slot.intern_user_id)
      }else{
        this.prelocation = ""
        this.location = ""
        this.person = this.small_user
        this.prePerson = this.small_user
        this.start_at = this._start_at
        this.end_at = this._end_at
        this.confirmation = null
        this.inviteMode = "user"
        this.preAgency = null
        this.agency = null
        this.preInternUser = null
        this.internUser = null
      }
    },
    deleteElement(){
      this.save({_delete: true, slot_id: this.event_slot ? this.event_slot.id : null, id: this.event_id})
    },
    save(extParams){
      this.location = this.prelocation
      this.person = this.prePerson
      this.agency = this.preAgency
      this.internUser = this.preInternUser
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(extParams || {
          slot_id: this.event_slot ? this.event_slot.id : null,
          id: this.event_id,
          user_id: (this.person && this.inviteMode === "user") ? this.person.id : null,
          intern_user_id: (this.internUser && (this.inviteMode === "internUser" || this.inviteMode === "externUser")) ? this.internUser.id : null,
          agency_id: (this.agency && this.inviteMode === "agency") ? this.agency.id : null,
          job_type: this.innerJob,
          slot: this.slot,
          end_at: this.end_at,
          start_at: this.start_at,
          confirmation: this.confirmation,
          location: this.location,
        })
      }
      fetch("/staff/events/slot", options).then(x => x.json()).then((response) => {
        this.$emit('update:modelValue', response)
      })
    }
  }
}
</script>

<style lang="scss">
  .select-avatar{
    width: 35px;
    height: 35px;
    margin-left: -10px;
    margin-right: 8px;
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .v-select{
    min-width: 250px;
  }
  .name-cut{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;    
  }
</style>