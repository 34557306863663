<template lang="pug">
.m-3
  input(type="hidden" :value="selectedGroup.map(x => x.id)" name="staff_event[groups]")
  input(type="hidden" :value="otherSelectedUsers.map(x => x.id)" name="staff_event[selected_users]")
  input(type="hidden" :value="unSelecetUsers" name="staff_event[none_users]")
  label.form-label Gruppen hinzufügen
  v-select(:options="groupOptions" placeholder="Gruppen einladen" v-model="selectedGroup" multiple label="name")
  #selectedAccordion.accordion.mt-4
    .accordion-item
      h2.accordion-header
        button.accordion-button.collapsed(type="button" data-bs-toggle="collapse" data-bs-target="#selectedAccordionOne" aria-expanded="false" aria-controls="selectedAccordionOne")
          span(v-if="finalSelecedUsers.length == 1") 1 Person wird eingeladen
          span(v-else) {{finalSelecedUsers.length}} Personen werden eingeladen
      #selectedAccordionOne.accordion-collapse.collapse(data-bs-parent="#selectedAccordion"): .accordion-body.py-2
        v-select(:options="otherUsers" placeholder="Personen einladen" v-model="otherSelectedUsers" multiple label="name")
          template(v-slot:option="option")
            img.rounded-circle.select-avatar(:src="option.avatar")
            | {{ option.name }}
          template(#selected-option="option")
            img.rounded-circle.select-avatar(:src="option.avatar" style="margin-left: -2px;")
            | {{ option.name }}
          template(#no-options="{ search, searching, loading }") Keine Person gefunden.
        table.table.mt-5: tbody
          tr(v-for="user in selectedUsers")
            td: img.rounded-circle(:src="user.avatar" style="width: 50px;")
            td {{user.name}}
            td
              input(class="form-check-input" type="checkbox" style="scale: 1.4;" v-if="unSelecetUsers.includes(user.id)" @input="x => setUser(x, user)")
              input(class="form-check-input" type="checkbox" style="scale: 1.4;" v-else checked @input="x => setUser(x, user)")


</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect
  },
  props: [
    "_users",
    "_staff_groups",
    "_groups",
    "_selected_users",
    "_none_users",
  ],
  data () {
    return {
      selectedGroup: [],
      otherSelectedUsers: [],
      unSelecetUsers: []
    }
  },
  mounted () {
    if (this._groups) this.selectedGroup = this._groups.split(",").map( x => this.groupOptions.find(y => y.id == x)).filter( x => x)
    if (this._selected_users) this.otherSelectedUsers = this._selected_users.split(",").map( x => this.users.find(y => y.id == x)).filter( x => x)
    if (this._none_users) this.unSelecetUsers = this._none_users.split(",").map(x => parseInt(x)).filter( x => x)
  },
  watch: {
  },
  computed: {
    users(){
      return JSON.parse(this._users)
    },
    groups(){
      return JSON.parse(this._staff_groups)
    },
    selectedUsers(){
      let data = this.otherSelectedUsers
      this.selectedGroup.map( group => {
        data = data.concat(this.users.filter( user => user.staff_group == group.id))
      })
      return data
    },
    finalSelecedUsers(){
      return this.selectedUsers.filter( user => !this.unSelecetUsers.includes(user.id))
    },
    otherUsers(){
      return this.users.filter(x => !this.selectedUsers.includes(x))
    },
    groupOptions(){
      return Object.keys(this.groups).map( key => { return {id: key, name: this.groups[key]} } )
    }
  },
  methods: {
    setUser(x, user){
      if (x.target.checked){
        this.unSelecetUsers = this.unSelecetUsers.filter(x => x != user.id)
      }else{
        this.unSelecetUsers.push(user.id)
      }
    }
  }
}
</script>

<style lang="scss">

</style>