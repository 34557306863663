<template lang="pug">
div
  label.form-label {{placeholder}}
  v-select(
    :placeholder="placeholder"
    v-model="contact"
    label="fullname"
    @search="onSearch"
    :filterable="false"
    :options="options"
    :taggable="!nonetaggable"
    :push-tags="!nonetaggable"
  )
    template(#no-options="{ search, searching, loading }") Keinen Kontakt gefunden.
</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect
  },
  props: [
    "record",
    "contactname",
    "placeholder",
    "nonetaggable",
    "showall"
  ],
  data () {
    return {
      contact: null,
      options: []
    }
  },
  mounted () {
    if (this.contactname && this.contactname.length != 0){
      this.contact = {fullname: this.contactname, init: true}
    }
  },
  watch: {
    contact(){
      if (this.contact.init) return true
      this.$emit('contact', this.contact)
      if (!this.record) return true
      let fields = [
        "firstname",
        "lastname",
        "street",
        "street_number",
        "city",
        "zip",
        "country",
        "phone",
        "email",
      ]

      fields.map( x=> {
        document.getElementsByName(this.record+"[customer_XXXXXX]".replace("XXXXXX", x)).forEach( y => y.value = this.contact[x]||"" )
      })

      if (this.contact.salutation) document.getElementsByName(this.record+"[customer_salutation]").forEach( y => y.value = this.contact.salutation )
      document.getElementsByName(this.record+"[crm_contact_id]").forEach( y => y.value = this.contact.contact_id||"" )
      document.getElementsByName(this.record+"[customer_mobile]").forEach( y => y.value = this.contact.phone||"" )
      document.getElementsByName(this.record+"[customer_company_name]").forEach( y => y.value = this.contact.company || this.contact.fullname || this.contact || "" )
      document.getElementsByName(this.record+"[customer_country]").forEach( y => y.value = this.contact.country || "DE" )
      document.getElementsByName(this.record+"[customer_billing_email]").forEach( y => y.value = this.contact.billing_email || "")
    }
  },
  computed: {
  },
  methods: {
    onSearch(search, loading) {
      if(search.length >= 3) {
        loading(true);
        fetch(`/crm/contacts.json?q=${search}${this.showall ? "" : "&company=true"}`).then(res =>  res.json()).then(data => {
          this.options = data
          loading(false);
        });
      }
    },
  }
}
</script>

<style lang="scss">

</style>