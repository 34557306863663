import { createApp } from 'vue'
import App from './App.vue'

document.addEventListener("turbo:load", () => {
  const mountEl = document.querySelector("#speechAssistent");
  if (mountEl){
    const app = createApp(App, { ...mountEl.dataset, innerHtml: mountEl.innerHTML })
    app.mount('#speechAssistent')
  }
});

