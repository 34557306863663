import { createApp } from 'vue'
import App from './App.vue'

document.addEventListener("turbo:load", () => {

  document.querySelectorAll('#company-select-app').forEach(function(mountEl) {
    const app = createApp(App, { ...mountEl.dataset})
    app.mount("#"+mountEl.id)
  });
});

