<template lang="pug">
div
  
  .btn-group.btn-sm.float-end
    button.btn.btn-secondary.btn-sm(:class="viewMode == 'all' ? 'active' : ''" type="button" @click="viewMode = 'all'") Planansicht
    button.btn.btn-secondary.btn-sm(:class="viewMode == 'scheduler' ? 'active' : ''" type="button" @click="viewMode = 'scheduler'") Zeitansicht
  br
  br
  div(v-if="viewMode == 'scheduler'")
    table.table(v-if="presentSlots.length != 0")
      thead
        tr
          th(style="width: 10%; cursor: pointer; -ms-user-select: none; -webkit-user-select: none; user-select: none;" @click="setSort('sort_name')") 
            | Akteur
            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; margin-top: -5px;" v-if="sortKey == 'sort_name'")
              path(d="M7,15L12,10L17,15H7Z" fill="currentColor" v-if="!reverse")
              path(d="M7,10L12,15L17,10H7Z" fill="currentColor" v-else)
          th(style="width: 10%; cursor: pointer; -ms-user-select: none; -webkit-user-select: none; user-select: none;" @click="setSort('sort_job')") 
            | Job
            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; margin-top: -5px;" v-if="sortKey == 'sort_job'")
              path(d="M7,15L12,10L17,15H7Z" fill="currentColor" v-if="!reverse")
              path(d="M7,10L12,15L17,10H7Z" fill="currentColor" v-else)

          th(style="width: 10%; cursor: pointer; -ms-user-select: none; -webkit-user-select: none; user-select: none;" @click="setSort('sort_location')") 
            | Ort
            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; margin-top: -5px;" v-if="sortKey == 'sort_location'")
              path(d="M7,15L12,10L17,15H7Z" fill="currentColor" v-if="!reverse")
              path(d="M7,10L12,15L17,10H7Z" fill="currentColor" v-else)

          th(v-for="i in timeDiff()+1" :style="'width: ' + 70 / (timeDiff() + 1 ) + '%'") 
            span(v-if="minStartAt+i-1 >= 24") {{minStartAt+i-1-24}}:00
            span(v-else) {{minStartAt+i-1}}:00
      tbody
        tr(v-for="slot in presentSlots")
          td(style="width: 10%")
            avatar(
              :event_id="id"
              :key="slot.slot"
              :slot="slot.slot"
              :job="slot.job_type"
              :users="getUsersByJob(slot.slot, false, true)"
              :event_slot="getSlot(slot.job_type, slot.slot)"
              :jobs="parsedJobs"
              v-model="eventSlots"
              :_start_at="start_at"
              :_end_at="end_at"
              :locations="parsedLocations"
              :agencies="parsedAgencies"
              :internusers="internUsers"
              :externusers="externUsers"
              :disablebottom="true"
            )
          td(style="width: 10%") {{slot.sort_job}}
          td(style="width: 10%") {{slot.location}}
          td(:style="'width: ' + 70 / (timeDiff() + 1 ) + '%'" v-for="i in timeDiff()" :colspan="getHour(slot.start_at) == minStartAt+i-1 ? timeDiff(getHour(slot.end_at), getHour(slot.start_at)) : 0")
            .bg-primary.text-white.text-center.py-3(v-if="getHour(slot.start_at) == minStartAt+i-1 ? 'bg-danger' : ''") 
              span {{getTime(slot.start_at)}} Uhr 
              span(v-if="slot.end_at") bis {{getTime(slot.end_at)}} Uhr


  div(v-else v-for="(count, key) in parsedCounts"): div(v-if="isCountPresent(count)")
    .float-end
      button.btn.btn-primary.btn-sm(v-if="freeSlot(key, parseInt(count))" type="button" @click="openAgencyModal(key)")
        svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 16px; margin-right: 5px;")
          path(d="M13.4 21C13.2 20.4 13 19.7 13 19C13 17.5 13.6 16.1 14.5 15H13V13H15V14.5C15.6 14 16.3 13.6 17 13.3V3H3V21H9V17.5H11V21H13.4M13 5H15V7H13V5M13 9H15V11H13V9M7 19H5V17H7V19M7 15H5V13H7V15M7 11H5V9H7V11M7 7H5V5H7V7M9 5H11V7H9V5M9 9H11V11H9V9M9 15V13H11V15H9M20 15V18H23V20H20V23H18V20H15V18H18V15H20Z" fill="currentColor")
        | Agentur zuweisen
      button.btn.btn-primary.btn-sm.disabled(v-else type="button")
        svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 16px; margin-right: 5px;")
          path(d="M13.4 21C13.2 20.4 13 19.7 13 19C13 17.5 13.6 16.1 14.5 15H13V13H15V14.5C15.6 14 16.3 13.6 17 13.3V3H3V21H9V17.5H11V21H13.4M13 5H15V7H13V5M13 9H15V11H13V9M7 19H5V17H7V19M7 15H5V13H7V15M7 11H5V9H7V11M7 7H5V5H7V7M9 5H11V7H9V5M9 9H11V11H9V9M9 15V13H11V15H9M20 15V18H23V20H20V23H18V20H15V18H18V15H20Z" fill="currentColor")
        | Agentur zuweisen
      button.btn.btn-primary.btn-sm(type="button" style="margin-left: 5px;" @click="addCount(key)")
        svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 16px;")
          path(d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" fill="currentColor")
    h4 {{parsedJobs[key]}}
    .d-flex.align-items-center.my-4(style="flex-wrap: wrap;")
      avatar(
        v-for="index in parseInt(count)"
        :event_id="id"
        :key="index"
        :slot="index"
        :job="key"
        :users="getUsersByJob(key, false, true)"
        :event_slot="getSlot(key, index)"
        :jobs="parsedJobs"
        v-model="eventSlots"
        :_start_at="start_at"
        :_end_at="end_at"
        :locations="parsedLocations"
        :agencies="parsedAgencies"
        :internusers="internUsers"
        :externusers="externUsers"
      )
    div(style="margin-top: -15px; margin-bottom: 30px;")
      avatar(
        v-for="user in getUsersByJob(key)"
        :small_user="user"
        :event_id="id"
        :job="key"
        :users="getUsersByJob(key, true)"
        :jobs="parsedJobs"
        v-model="eventSlots"
        :_start_at="start_at"
        :_end_at="end_at"
        :locations="parsedLocations"
        :agencies="parsedAgencies"
        :internusers="internUsers"
        :externusers="externUsers"
      )
  div(v-if="otherUsers.length != 0")
    h4 Eingeladene
    div(style="margin-top: -15px; margin-top: 20px;")
      avatar(
        v-for="user in otherUsers"
        :small_user="user"
        :event_id="id"
        :users="otherUsers"
        :jobs="parsedJobs"
        v-model="eventSlots"
        :_start_at="start_at"
        :_end_at="end_at"
        :locations="parsedLocations"
        :agencies="parsedAgencies"
        :internusers="internUsers"
        :externusers="externUsers"
      )
  .modal.fade(tabindex='-1' id="agencyModal" aria-hidden='true')
    .modal-dialog
      .modal-content
        .modal-header
          h1.modal-title.fs-5 Agentur besetzen
          button.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
        .modal-body
          label.form-label Eingeteilter Job
          select.form-select.mb-3(v-model="agencyBatch.job_type")
            option(v-for="(value, key) in parsedJobs" :value="key") {{value}}
          label.form-label Agentur
          v-select(:options="parsedAgencies" label="name" :clearable="false" placeholder="Agentur auswählen" v-model="agencyBatch.agency")
          .row.mt-3
            .col-12: label.form-label Einsatzzeiten
            .col-5: input.form-control(v-model="agencyBatch.start_at" type="time")
            .col-2.text-center —
            .col-5: input.form-control(v-model="agencyBatch.end_at" type="time")
          label.form-label.mt-4(for="location") Einsatzort
          v-select(:options="parsedLocations" placeholder="Einsatzort" taggable push-tags v-model="agencyBatch.location")
          label.form-label.mt-4(for="count") Anzahl
          input.form-control#count(v-model="agencyBatch.count" type="number" placeholder="Anzahl")
        .modal-footer
          button.btn.btn-secondary(type='button' data-bs-dismiss='modal') Schließen
          button.btn.btn-primary(type='button' @click="saveAgencyBatch" data-bs-dismiss='modal') Speichern

</template>

<script>
import vSelect from "vue-select";
import Avatar from "./Avatar.vue"
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Avatar,
    vSelect
  },
  props: [
    "id",
    "users",
    "jobs",
    "counts",
    "event_slots",
    "start_at",
    "end_at",
    "locations",
    "agencies"
  ],
  data () {
    return {
      agencyBatch: {},
      viewMode: "all",
      eventSlots: null,
      sortKey: "sort_name",
      reverse: false,
      parsedCounts: []
    }
  },
  mounted () {
    this.parsedCounts = JSON.parse(this.counts) || {}
  },
  watch: {
  },
  computed: {
    presentSlots(){
      return this.parsedEventSlots.filter(x => (x.user_id && x.staff_state === "yes") || x.intern_user_id || x.agency_id).filter(x => x.start_at && x.end_at).map( x => {
        if (x.user_id) {
          x.sort_name = this.parsedUsers.find(y => y.id == x.user_id).name
        } else if (x.agency_id){
          x.sort_name = this.parsedAgencies.find(y => y.id === x.agency_id).name
        }else if (x.intern_user_id){
          x.sort_name = this.internUsers.find(y => y.id === x.intern_user_id).name
        }
        x.sort_job = this.parsedJobs[x.job_type]
        x.sort_location = x.location
        return x
      }).sort((a,b) => {
        let sort = a[this.sortKey] > b[this.sortKey] ? 1 : -1
        if (this.reverse) sort = sort * -1
        return sort
      })
    },
    internUsers(){
      return this.parsedUsers.filter(x => x.employee_type == "intern")
    },
    externUsers(){
      return this.parsedUsers.filter(x => x.employee_type == "staff")
    },
    parsedLocations(){
      return JSON.parse(this.locations)
    },
    parsedAgencies(){
      return JSON.parse(this.agencies)
    },
    parsedUsers(){
      return JSON.parse(this.users)
    },
    otherUsers(){
      return this.parsedEventSlots.filter(x => x.staff_state != "yes" || !this.isCountPresent(this.parsedCounts[x.job_type]) || !x.job_type).map( x => {
        let user = this.parsedUsers.find(y => y.id == x.user_id)
        if (user) user.staff_state = x.staff_state 
        return user
      }).filter(x => x)  
    },
    parsedJobs(){
      let data = {}
      let jobs = JSON.parse(this.jobs)
      Object.keys(jobs).map(key => {
        if (this.parsedCounts[key] != '0') data[key] = jobs[key]
      })
      return data
    },
    parsedEventSlots(){
      return this.eventSlots || JSON.parse(this.event_slots)
    },
    minStartAt(){
      return Math.min(...this.presentSlots.map(x => this.getHour(x.start_at)))
    }
  },
  methods: {
    addCount(job_type){
      this.parsedCounts[job_type] = parseInt(this.parsedCounts[job_type]) + 1
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "PUT",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({staff_event: {staff_counts: this.parsedCounts}, json: true})
      }
      fetch("/staff/events/"+this.id, options)
    },
    openAgencyModal(job_type){
      this.agencyBatch = {
        id: this.id,
        job_type: job_type,
        start_at: this.start_at,
        end_at: this.end_at,
        count: 1,
        agency: null,
      }
      this.agencyModal = new bootstrap.Modal(document.getElementById("agencyModal"))
      this.agencyModal.show()
    },
    saveAgencyBatch(){
      this.agencyBatch.agency_id = this.agencyBatch.agency.id
      this.agencyBatch.agency = null
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(this.agencyBatch)
      }
      fetch("/staff/events/slot/batch", options).then(x => x.json()).then((response) => {
        this.eventSlots = response
      })
    },
    setSort(key){
      if (key == this.sortKey) this.reverse = !this.reverse
      this.sortKey = key
    },
    timeDiff(end_at, start_at){
      let start = start_at || this.minStartAt
      let end = end_at || Math.max(...this.presentSlots.map(x => {
        let end = this.getHour(x.end_at)
        if (end < start) end += 24
        return end
      })) || 24
      if (end < start) end += 24
      return Math.abs(end - start)
    },    
    getTime(string){
      return ((string||"").split("T")[1]||"").split(":00.000")[0]
    },
    getHour(string){
      const d = new Date(string);
      return d.getHours();
    },
    parseTime(string){
      return parseInt(string.split(":")[0])
    },
    isCountPresent(count){
      return !!parseInt(count)
    },
    variant(event_slot){
      if (event_slot.staff_state == "pending"){
        return "secondary"
      }else if (event_slot.staff_state == "yes"){
        return "success"
      }else if (event_slot.staff_state == "no"){
        return "danger"
      }else if (event_slot.staff_state == "maybe"){
        return "warning"
      }
    },
    getUsersByJob(job, all, allJobs){
      return this.parsedEventSlots.filter(x => x.staff_state == "yes" || all ).filter(x => !x.slot || ((all || allJobs) && !this.isCountPresent(this.parsedCounts[x.job_type]))).map( x => {
        let user = this.parsedUsers.find(y => y.id == x.user_id)
        if (!user) return null
        user.slot_id = x.id
        user.job = x.job_type
        user.staff_state = x.staff_state
        user.info = x.info
        user.start_at = x.start_at
        user.end_at = x.end_at
        user.confirmation = x.confirmation
        return user
      }).filter( x => x && (all || allJobs || x.job == job) )
    },
    getSlot(job, index){
      let slot = this.parsedEventSlots.find(s => s.job_type == job && s.slot == index)
      if (slot) slot.user = this.parsedUsers.find(u => u.id == slot.user_id)
      return slot
    },
    freeSlot(job, count){
      let slots = []
      for(let i = 1; i <= count; i++){
        if (!this.parsedEventSlots.find(s => s.job_type == job && s.slot == i)){
          slots.push(i)
        }
      }
      return slots[0]
    }
  }
}
</script>

<style lang="scss">

</style>