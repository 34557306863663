<template lang="pug">
div
  .alert.alert-success(v-if="success")
    h4.text-success.m-0 Bestellung wurde erfasst!
    small(v-if="receiptNumber") Belegnummer: {{receiptNumber}}
  .row
    .col-xl
      .card.mb-4
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Bestellung
        .card-body
          .mb-3(v-for="(o, i) in orderItems")
            .repeater-wrapper.pt-0.pt-md-4
              .d-flex.border.rounded.position-relative.pe-0
                .row.w-100.m-0.p-3
                  .col-12: .mb-3
                    .alert.alert-danger(v-if="o.error") EAN ungültig, bitte prüfen oder Neu erfassen!
                    label.form-label(:for="'ean_'+i") EAN *
                    .input-group
                      input.form-control(type="text", placeholder="EAN *", v-model="o.ean", :id="'ean_'+i" @keyup.enter="submitOrderItem(i)")
                      .input-group-text.d-none.d-sm-inline(v-if="o.loading"): .spinner-border.spinner-border-sm(role='status')
                  .col-6.col-sm-8(v-if="o.ticket"): .mb-3
                    h5(style="margin-top: 40px;") {{o.ticket}}
                  .col-6.col-sm-4(v-if="o.ticket"): .mb-3
                    label.form-label(:for="'product_price_'+i") Preis *
                    .input-group
                      input.form-control(type="number", placeholder="Preis *", v-model="o.product_price", :id="'product_price_'+i")
                      .input-group-text.d-none.d-sm-inline €
                .d-flex.flex-column.align-items-center.justify-content-between.border-start.p-2
                  svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; height: 24px; cursor: pointer" @click="removeOrderItem(i)"): path(fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z")
    .col-xl
      .card.mb-4
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Zusammenfassung
        .card-body
          #no-more-tables: table.table
            thead
              tr
                th Ticket
                th EAN
                th Nummer
                th Gesamtpreis
            tbody(v-for="(o, i) in validOrderItems")
              tr
                td(data-title="Name") {{o.ticket}}
                td(data-title="EAN") {{o.ean}}
                td(data-title="Numer") {{o.number}}
                td(data-title="Gesamtpreis") {{toCurrency(o.product_price)}}
            tfoot
              tr
                th(colspan="2"): h5.my-1.m-0 Anzahl {{validOrderItems.length}}
                th: h5.my-1.m-0 Summe
                th: h5.my-1.m-0: strong {{toCurrency(orderSum)}}
      .card.mb-4
        .card-header.d-flex.justify-content-between.align-items-center
          h4.mb-0 Buchung
        .card-body
          .row
            .col-6
              .mb-3
                label.form-label(for="paymentMethod") Zahlungsmethode
                select#paymentMethod.form-select(v-model="intern_payment_method", placeholder="Zahlungsmethode")
                  option(:value="id" v-for="(name, id) in paymentMethods") {{name}}
            .col-6
              .mb-3
                label.form-label(for="email") Email für Rechnung
                input#own_transaction_id.form-control(type="email", placeholder="Email für Rechnung", v-model="email")  
            //- .col-12
            //-   .form-check.form-switch.mt-2.mb-3
            //-     input.form-check-input#pdf(type="checkbox", v-model="pdf")
            //-     label.form-check-label(for="pdf") Kunde wünscht Rechnungsausdruck
          .row
            .col-12.mt-4
              .d-grid: button.btn.btn-primary.btn-lg(type="button" @click="submitOrder" :class="{disabled: loading || error}")
                span.spinner-border.spinner-border-sm(style="margin-right: 10px;" v-if="loading")
                span JETZT BUCHEN


</template>
<script>
export default {
  components: {
  },
  props: [
    "pos"
  ],
  data () {
    return {
      receiptNumber: null,
      success: false,
      loading: false,
      email: "",
      intern_payment_method: "card",
      pdf: false,
      orderItems: [],
    }
  },
  mounted () {
    this.addNewOrderItem()
  },
  watch: {
  },
  computed: {
    error(){
      return this.validOrderItems.length == 0 || this.orderItems.filter( x => x.error).length != 0
    },
    orderSum(){
      return this.validOrderItems.map(x => x.product_price).reduce((a, b) => a + b, 0)
    },
    validOrderItems(){
      return this.orderItems.filter( x => x.ticket && x.product_price)
    },
    paymentMethods(){
      if (this.pos){
        return {
          card: "Kartenzahlung (POS Terminal "+this.pos+")",
          bar: "Bar",
        }
      } else {
        return {
          card: "Kartenzahlung",
          bar: "Bar",
        }
      }
    }
  },
  methods: {
    init(){
      // if (this.pdf) window.open("https://tickets-dresdner-schloessernacht.de/order/", '_blank').focus();
      this.loading = false
      this.success = true
      this.email = ""
      this.orderItems = []
      this.intern_payment_method = "card"
      this.pdf = false
      this.addNewOrderItem()
    },
    toCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    },
    removeOrderItem(i){
      this.orderItems = this.orderItems.filter((_x, x) => {
        return i != x
      })
    },
    addNewOrderItem(){
      if (this.orderItems.length == 0 || Object.keys(this.orderItems[this.orderItems.length-1]).length != 0) this.orderItems.push({})
      const count = this.orderItems.length - 1
      setTimeout(function() {
        document.getElementById("ean_"+count).focus()
      }, 250)
    },
    submitOrder(){
     this.loading = true
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      options.body = JSON.stringify({
        pos: this.pos,
        email: this.email,
        intern_payment_method: this.intern_payment_method,
        pdf: this.pdf,
        order_items: this.orderItems
      })

      fetch("/ticketshops/orders/checkout.json", options).then(x => x.json()).then((data) => {
        this.loading = false
        if (data.status === "success") {
          this.init()
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.receiptNumber = data.receipt_number
        } else {
          alert(data.error)
        }
      })
    },
    submitOrderItem(i){
      this.addNewOrderItem()
      this.orderItems[i].loading = true
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      options.body = JSON.stringify({code: this.orderItems[i].ean, nocheck: true})
      fetch("/ticketshops/orders/check.json", options).then(x => x.json()).then((data) => {
        this.orderItems[i].loading = false
        if (data.status === "success") {
          this.orderItems[i].error = false
          this.orderItems[i].ticket = data.full_name + " (" + data.number + ")"
          this.orderItems[i].product_price = data.price
          this.orderItems[i].number = data.number
          this.orderItems[i].tax = data.tax
        } else {
          this.orderItems[i].error = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>