<template lang="pug">
div
  .d-grid(v-if="!start"): button.btn.btn-primary(@click="start=true") Kamera öffnen
  StreamBarcodeReader(
    :msBetweenDecoding="1"
    @decode="onDecode"
    v-if="start"
  )
  div(:class="{'fade-hidden': !fade}" @click="fade = false")
    .alert.alert-danger.text-center.mt-5(v-if="data.status == 'error'")
      h1.text-danger Verbindungsfehler
      strong Bitte erneut versuchen oder Support rufen
    .alert.alert-info.text-center.mt-5(v-if="data.status == 'pending'")
      h1.text-info Late-Night - Einlass ab 23 Uhr!
      strong(v-if="result") Code: {{result}} 
    .alert.alert-warning.text-center.mt-5(v-if="data.status == 'dup'")
      h1.text-warning Ticket bereits gescannt!
      strong(v-if="result") Code: {{result}} 
      div: strong Zeit: {{data.date}} - {{data.device}}
      div(v-if="isCheck2"): strong Name: {{data.c_name}}
    .alert.alert-danger.text-center.mt-5(v-if="data.status == 'not_found'")
      h1.text-danger Ticket ungültig!
      strong(v-if="result") Code prüfen: {{result}}
      .mt-3: strong ... oder Gast an F+F verweisen
    div(v-if="data.status == 'success'")
      div(v-if="isCheck2") 
        .alert.alert-success.text-center.mt-5(v-if="data.name == 'VIP'")
          h1.text-success Bändchen ausgeben!
          div: strong Code: {{data.code}}
          div: strong Ticketnummer: {{data.number}}
          div: strong Ticketart: {{data.name}}
          div(v-if="isCheck2"): strong Name: {{data.c_name}}
        .alert.alert-danger.text-center.mt-5(v-else)
          h1.text-danger Kein VIP
          div: strong Code: {{data.code}}
          div: strong Ticketnummer: {{data.number}}
          div: strong Ticketart: {{data.name}}
          div(v-if="isCheck2"): strong Name: {{data.c_name}}
      .alert.alert-success.text-center.mt-5(v-else)
        h1.text-success(v-if="data.name == 'VIP'") VIP Ticket, Bändchen Hinweis
        h1.text-success(v-else) Ticket gültig!
        div: strong Code: {{data.code}}
        div: strong Ticketnummer: {{data.number}}
        div: strong Ticketart: {{data.name}}
        div(v-if="isCheck2"): strong Name: {{data.c_name || "Unbekannt"}}
  form(@submit="(e) => {e.preventDefault(); onDecode(number)}")
    .input-group.input-group-lg.mt-5
      input.form-control(placeholder='Code' type='text' style='font-weight: 700; font-size: 22px;' v-model="number")
      button.btn.btn-secondary(type='button' style='font-weight: 800; font-size: 24px;' @click="onDecode(number)") CHECK
</template>

<script>
import StreamBarcodeReader from './StreamBarcodeReader.vue'
export default {
  components: {
    StreamBarcodeReader
  },
  props: [
    "token",
    "device",
    "type"
  ],
  data () {
    return {
      number: "",
      start: false,
      fade: false,
      block: false,
      result: "",
      data: {}
    }
  },
  mounted () {
  },
  watch: {
  },
  computed: {
    isCheck2(){
      return this.type === 'check2'
    }
  },
  methods: {
    onDecode(x){
      if (!x || x.length == 0 || (this.result === x && this.block)) return false
      this.result = x
      this.sendData({code: x})
    },
    sendData(params){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      params.token = this.token
      params.device = this.device
      params.type = this.type
      options.body = JSON.stringify(params)

      fetch("/ticketshops/orders/check.json", options).then(x => x.json()).then((data) => {
        this.data = data
        this.block = true
        this.fade = true
        if (data.status == 'success' && (data.name == 'VIP' || !this.isCheck2)){
          const audio = new Audio("/audio/beep.mp3");
          audio.play();          
        }else{
          const audio = new Audio("/audio/error.mp3");
          audio.play();            
        }
        let self = this
        setTimeout(() => {
          if (self.type != 'check2') self.fade = false
        }, 4000)
        setTimeout(() => {
          self.block = false
        }, 1500)

      }).catch((error) => {
        this.block = true
        this.data = {status: "error"}
        let self = this
        setTimeout(() => {
          self.block = false
          self.fade = false
        }, 5000)

      });
    }


  }
}
</script>

<style lang="scss">
</style>