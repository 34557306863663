<template lang="pug">
div
  .card.mb-5(v-if="list=='true'")
    .card-header(v-if="!screen_id || !screen_id.startsWith('screen_3')")
      .row
        .col-12.col-sm-6
          img(:src="data.logo" width="120" v-if="data.logo")
        .col-12.col-sm-3(v-if="ipad!='true' && ipad!='booking'")
          div
            div
              span {{checkedInCount}} / {{countBy()}}  - {{Math.round(checkedInCount / countBy() * 100)}}%
            .progress(role="progressbar")
              .progress-bar(:style="{width: Math.round(checkedInCount / countBy() * 100)+'%'}") 
        .col-12.col-sm-3(v-if="ipad!='true' && ipad!='booking'"): input(type="text" class="form-control" placeholder="Suche" v-model="search" )
        .col-12.col-sm-6(:class="{'text-end': ipad == 'true' || ipad == 'booking'}")
          .btn-group.mt-4(v-if="!screen_id && ipad==='true'" style="margin-right: 20px;")
            button.btn.btn-secondary.dropdown-toggle.btn-sm(type="button", data-bs-toggle="dropdown")
              span(style="margin-right: 10px;") Auf Bildschirm übertragen
            ul.dropdown-menu
              li: button.dropdown-item(type="button" @click="notifyScreen('screen_1')") Bildschirm Mafia Mia
              li: button.dropdown-item(type="button" @click="notifyScreen('screen_2')") Bildschirm Moments
              li: button.dropdown-item(type="button" @click="notifyScreen('screen_3')") Bildschirm Küche
          h4.mt-4 {{title}}
    list(
      :ipad="ipad"
      :data="data"
      :read_only="read_only"
      :screen_id="screen_id"
      @update="updateTable"
      v-if="data.logo"
    )
  div(v-else)
    .card.mb-5.d-print-none
      .card-header.mb-2
        .row
          .col-12.col-sm-3
            img(:src="data.logo" width="120" v-if="data.logo")
            h4.mt-4 
              strong.text-danger(v-if="data.cancel_email_sendet_at"): div ABGESAGT
              | {{title}}
            div(v-if="data.mailFrom" style="margin-top: -15px"): small Import: {{data.mailFrom}}
          .col-12.col-sm-6.mt-1.text-end(style="height: 40px;")
            .btn-group.btn-sm.p-0(v-if="read_only=='false'" style="margin-right: 10px")
              button.btn.btn-primary.dropdown-toggle.btn-sm(type="button", data-bs-toggle="dropdown" :disabled="mailSending")
                span(class="spinner-border spinner-border-sm" style="margin-right: 10px;" v-if="mailSending")
                span(style="margin-right: 10px;") Emails versenden
              ul.dropdown-menu
                li: button.dropdown-item(type="button" style="margin-left: 10px;" @click="openCodeModal('sendMail'); data.customer_email_sendet_at=true" :disabled="mailSending || data.customer_email_sendet_at") Gäste benachrichtigen
                li: button.dropdown-item(type="button" style="margin-left: 10px;" @click="sendMail('intern')" :disabled="mailSending") Team benachrichtigen
                li: button.dropdown-item(type="button" style="margin-left: 10px;" @click="sendMail('statistic')" :disabled="mailSending") Auswertung versenden 
            .btn-group.btn-sm.p-0(style="margin-right: 10px")
              button.btn.btn-primary.dropdown-toggle.btn-sm(type="button", data-bs-toggle="dropdown" :disabled="download")
                span(class="spinner-border spinner-border-sm" style="margin-right: 10px;" v-if="download")
                span(style="margin-right: 10px;") PDF Download
              ul.dropdown-menu
                li: button.dropdown-item(type="button" style="margin-left: 10px;" @click="downloadPDF('', 'Saalplan_')" :disabled="download") Saalplan
                li: button.dropdown-item(type="button" style="margin-left: 10px;" @click="downloadPDF('/cards', 'Tischkarten_')" :disabled="download") Tischkarten
                li: button.dropdown-item(type="button" style="margin-left: 10px;" @click="downloadPDF('/list', 'Gästeliste_')" :disabled="download") Gästeliste
                li: button.dropdown-item(type="button" style="margin-left: 10px;" @click="downloadPDF('/statistic', 'Auswertung_')" :disabled="download") Auswertung
            button.btn.btn-danger.btn-sm(type="button" @click="openCancelModal" v-if="read_only=='false'" :disabled="data.cancel_email_sendet_at")
              span Absagen
            .btn-group.btn-sm.p-0(v-if="read_only=='false'" style="margin-left: 10px")
              button.btn.btn-danger.btn-sm(@click="openCodeModal('import')") Änderungen verwerfen
          .col-12.col-sm-3.mt-1
            input(type="text" class="form-control" placeholder="Suche" v-model="search" )
            .form-check.form-switch.float-end.mt-3
              input.form-check-input#hoverMode(type="checkbox", v-model="data.hoverMode")
              label.form-check-label(for="hoverMode") Mauseffekt für gleiche Gäste
    .text-center.mt-5(v-if="loading"): .spinner-border(style="width: 150px; height: 150px;")
    div(v-else)
      .card.d-none.d-md-block(style="width: 200px; position: fixed; right: 15px; z-index: 99;"): .card-body
        table.table
          tr
            th Art
            th.text-end  Anzahl
          tr
            td Vegan
            td.text-end {{countBy("vegan", true)}}
          tr
            td BG
            td.text-end {{countBy("welcome_drink", true)}}
          tr
            td GP
            td.text-end {{countBy("drinks_flat_rate", true)}}
        table.table.mt-4
          tr
            th Kategorie
            th.text-end Anzahl
          tr
            td BT
            td.text-end {{countBy("category", "BT")}}/{{tableCountByCategory("BT")}}
          tr
            td K1
            td.text-end {{countBy("category", "K1")}}/{{tableCountByCategory("K1")}}
          tr
            td K2
            td.text-end {{countBy("category", "K2")}}/{{tableCountByCategory("K2")}}
          tr
            td K3
            td.text-end {{countBy("category", "K3")}}/{{tableCountByCategory("K3")}}
          tr
            th SUMME
            th.text-end {{countBy()}}/{{tableCountByCategory()}}


      .card.d-none.d-md-block(:style="{width: (backdropOpen ? '400px' : '200px'), position: 'fixed', left: '15px', maxHeight: '800px', overflow: 'scroll', zIndex: 99}")
        .card-body
          .text-end(style="margin-top: -15px;")
            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" @click="backdropOpen=!backdropOpen" style="width: 24px; height: 24px; cursor: pointer" v-if="backdropOpen"): path(d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M14,7L9,12L14,17V7Z")
            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" @click="backdropOpen=!backdropOpen" style="width: 24px; height: 24px; cursor: pointer" v-else): path(d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M10,17L15,12L10,7V17Z")
          table.table
            tr
              th Name
              th(v-if="backdropOpen") Tisch
              th(v-if="backdropOpen") Platz
              th(v-if="backdropOpen") Kommentar
            draggable(
              v-model="notPlaced"
              group="number"
              item-key="id"
              :sort="false"
              :move="checkMove"
              @end="endMove"
              ghost-class="ghost"
              tag="tbody"
              draggable=".drop-item"
            )
              template(#item="{element}")
                tr(:data-id="element.id" v-if="element.id" :class="{'drop-item': read_only=='false'}" :style="{color: categoryColor(element)}")
                  td(v-html="element.name")
                  td(v-if="backdropOpen") {{element.cach_table_number}}
                  td(v-if="backdropOpen") {{element.cach_col_number}}
                  td(v-if="backdropOpen") {{element.comment}}
                tr(:data-id="element.id" v-else)
                  td.text-center(colspan="5"): small ABLAGE

      div(style="width: 100%; overflow: scroll;"): div(:style="{'min-width': '1300px'}")
        chapiteau(:data="data" :read_only="read_only" @action="action" v-if="data.event_type == 'MOMENTS 2'")
        ostra-dome(:data="data" :read_only="read_only" @action="action" v-else)
  #codeModal.modal.fade(tabindex="-1", aria-hidden="true")
    .modal-dialog
      .modal-content(v-if="codeModalTyp == 'import'")
        .modal-header
          h1#exampleModalLabel.modal-title.fs-5 Sicherheitsabfrage
          button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
        .modal-body
          h3.text-center Bist du sicher das du alle Änderungen verwerfen möchtest ? 
          .mb-3
            label.form-label(for="code") Sicherheitscode
            input.form-control#vode(type="password", placeholder="Sicherheitscode", v-model="code")
        .modal-footer
          button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Abbrechen
          button.btn.btn-danger(type="button" data-bs-dismiss="modal" :disabled="code != '0815'", @click="fetch('import', {clear: 'clear'})") Ja, Änderungen verwerfen
      .modal-content(v-if="codeModalTyp == 'sendMail'")
        .modal-header
          h1#exampleModalLabel.modal-title.fs-5 Sicherheitsabfrage
          button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
        .modal-body
          h3.text-center Bist du sicher das du an ALLE Gäste eine Email senden möchtest ?
          .mb-3
            label.form-label(for="code") Sicherheitscode
            input.form-control#vode(type="password", placeholder="Sicherheitscode", v-model="code")
        .modal-footer
          button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Abbrechen
          button.btn.btn-primary(type="button" data-bs-dismiss="modal" :disabled="code != '0815'", @click="sendMail('customer')") Ja, Email senden
  #cancelModal.modal.fade(tabindex="-1", aria-hidden="true")
    .modal-dialog
      .modal-content
        .modal-header
          h1.modal-title.fs-5 Veranstaltung absagen
          button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
        .modal-body
          div(v-for="x in ['mm_1', 'mm_2', 'mm_3', 'm_1', 'm_2', 'm_3']")
            h6(v-if="x === 'm_1'") Moments
            h6(v-if="x === 'mm_1'") Mafia Mia
            select.form-select.mb-3( v-model="cancel[x]", placeholder="Produkt" :id="'cancel'+x" )
              option(:value="null") Keinen Ausweichtermin
              option(:value="p.id" v-for="p in parsedEvents( x.startsWith('mm') ? 'Mafia Mia' : 'MOMENTS 2')") {{p.date}}
          .mb-3
            label.form-label(for="infotext") Infotext
            textarea.form-control#infotext(placeholder="Infotext", v-model="infoText")
          .mb-3
            label.form-label(for="code") Sicherheitscode
            input.form-control#vode(type="password", placeholder="Sicherheitscode", v-model="code")
        .modal-footer
          button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Abbrechen
          button.btn.btn-primary(type="button" data-bs-dismiss="modal" :disabled="code != '0815'", @click="fetch('cancel', {cancel: cancel, info: infoText})") Gäste benachrichtigen

  #modalTable.modal.fade(tabindex="-1", aria-hidden="true")
    .modal-dialog
      .modal-content
        .modal-header
          h1.modal-title.fs-5 Tisch {{currentTicket[data.selector+'table_number']}} ({{currentTicket.category}}{{currentTicket.category != currentTicket.fixed_category ? (', ehem. '+currentTicket.fixed_category) : ""}})
          button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
        .modal-body
          h3.mb-0
            svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="heigh: 20px; width: 20px; margin-top:-5px; margin-right: 5px;"): path(d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" :fill="currentTicketColor")
            span(v-html="currentTicket.name")
          small K-Nr.: {{currentTicket.customer_number}}   Auf-Nr.: {{currentTicket.order_number}}  Anzahl: {{countBy("customer_number", currentTicket.customer_number)}}
          hr
          .mb-3
            label.form-label(for="name") Name - ALLE
            input.form-control#name(type="text", placeholder="Name - ALLE", v-model="name", :disabled="read_only=='true'")
          .mb-3
            label.form-label(for="comment") Kommentar - ALLE
            input.form-control#comment(type="text", placeholder="Kommentar - ALLE", v-model="comment", :disabled="read_only=='true'")
            i(style="color: #ff0000;" v-if="currentTicket.current_comment != currentTicket.comment" v-html="currentTicket.current_comment")

          .mb-3
            label.form-label(for="gastro_comment") Gastro Kommentar - ALLE
            input.form-control#gastro_comment(type="text", placeholder="Gastro Kommentar - ALLE", v-model="gastro_comment", :disabled="read_only=='true'")
          .mb-3
            label.form-label(for="kitchen_comment") Küchen Kommentar - ALLE
            input.form-control#kitchen_comment(type="text", placeholder="Küchen Kommentar - ALLE", v-model="kitchen_comment", :disabled="read_only=='true'")
          .mb-3.form-check.form-switch
            input.form-check-input(type="checkbox", v-model="welcomeDrink", :disabled="read_only=='true'")
            label.form-check-label Begrüßungsgetränk (BG) - ALLE
          .mb-3.form-check.form-switch
            input.form-check-input(type="checkbox", v-model="drinksFlatRate", :disabled="read_only=='true'")
            label.form-check-label Getränkepauschale (GP) - ALLE
          .mb-3.form-check.form-switch
            input.form-check-input(type="checkbox", v-model="isVegan", :disabled="read_only=='true'")
            label.form-check-label Veganes Menü
          .mb-3
            label.form-label(for="color") Bändchenfarbe - ALLE
            input.form-control#color(placeholder="Bändchenfarbe - ALLE", v-model="color", :disabled="read_only=='true'", type="color" style="height: 35px;")
        .modal-footer
          a.btn.btn-outline-primary(:href="'/ticketshops/orders/'+currentTicket.order_id" v-if="currentTicket.order_id" data-turbo="false") Bestellung öffnen
          button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Schließen
          button.btn.btn-primary(type="button" @click="updateTable()", data-bs-dismiss="modal" v-if="read_only=='false'") Speichern

</template>

<script>
import Draggable from 'vuedraggable/src/vuedraggable';
import OstraDome from "./OstraDome.vue"
import Chapiteau from "./Chapiteau.vue"
import List from "./List.vue"
import fileDownload from 'js-file-download';
export default {
  components: {
    List,
    Draggable,
    Chapiteau,
    OstraDome
  },
  props: [
    "title",
    "list",
    "ipad",
    "read_only",
    "screen_id",
    "events",
    "q"
  ],
  data () {
    return {
      infoText: "",
      backdropOpen: false,
      codeModalTyp: "",
      code: "",
      location: window.location.href.split("?")[0],
      currentTicket: {},
      mailSending: false,
      loading: true,
      data: {},
      search: "",
      isVegan: false,
      name: "",
      welcomeDrink: false,
      drinksFlatRate: false,
      color: "#ffffff",
      comment: "",
      download: false,
      cancel: {},
      gastro_comment: "",
      kitchen_comment: "",
    }
  },
  mounted () {
    this.fetch()
    this.initCancel()
  },
  watch: {
    search(){
      // setTimeout(x => this.data.search = this.search, 300);
      this.data.search = this.search
    }
  },
  computed: {
    currentTicketColor(){
      if (!this.data.tickets) return null
      const tickets = this.data.tickets.filter(x => x.customer_number == this.currentTicket.customer_number)
      if (tickets.length != tickets.filter(x => x.order_state == this.currentTicket.order_state).length) return '#ff9000'
      return this.currentTicket.order_state == 'reserviert' ? '#ff0000' : '#00ff00'
    },
    checkedInCount(){
      if (!this.data.tickets) return 0
      const group = this.groupBy(this.data.tickets.filter(x => x.checkin_count != 0), "customer_number")
      return Object.keys(group).map(x => parseInt(group[x][0].checkin_count)).reduce((a, b) => a + b, 0)
    },
    notPlaced(){
      if (!this.data.tickets) return []
      const sortBy = "fixed_category"
      const sortBy2 = "name"
      let tickets = this.data.tickets.filter(x => !x.table_number)
      tickets.push({})
      tickets = tickets.sort((a, b) => {
        if (a[sortBy] < b[sortBy])
          return -1 ;
        if (a[sortBy] > b[sortBy])
          return 1;
        if (a[sortBy2] < b[sortBy2])
          return -1 ;
        if (a[sortBy2] > b[sortBy2])
          return 1;
        return 0;
      })
      return tickets
    }
  },
  methods: {
    categoryColor(e){
      const t = Object.values(this.data.tables).find(x => x.category == e.fixed_category)
      if (!t) return "697a8d"
      return t.color
    },
    initCancel(){
      this.cancel = {m_1: null,m_2:null,m_3: null,mm_1: null,mm_2: null,mm_3: null}
    },
    parsedEvents(event_type){
      return JSON.parse(this.events).filter(x => x.event_type === event_type)
    },
    openCancelModal(){
      const cancelModalElement = document.getElementById('cancelModal')
      const cancelModal = new bootstrap.Modal(cancelModalElement)
      cancelModal.show()
    },
    openCodeModal(type){
      this.codeModalTyp = type
      const codeModalElement = document.getElementById('codeModal')
      const codeModal = new bootstrap.Modal(codeModalElement)
      codeModal.show()
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    notifyScreen(id){
      this.fetch("notify_screen", {screen_id: id})
    },
    sendMail(type){
      this.mailSending = true
      this.fetch("deliver/"+type, {})
      setTimeout(x => this.mailSending = false, 1200);
    },
    tableCountByCategory(value){
      if (!this.data.tables) return 0
      let tables = Object.values(this.data.tables)
      if (value) tables = tables.filter(t => t.category == value)

      return tables.map(x => (x.size_outside||x.size) - (x.block_cols ? x.block_cols.filter(b => x.size >= b).length : 0)).reduce((a, b) => a + b, 0)
    },
    countBy(key, value){
      if (!this.data.tickets) return 0
      if (!key) return this.data.tickets.length
      return this.data.tickets.filter(t => t[key] == value && t.col_number).length
    },
    endMove(evt){
      const to = evt.originalEvent.target.dataset
      if (to.block) return false
      const from = evt.item.dataset
      this.action({action: "move", to: to, from: from})
    },
    checkMove(evt){
      return false
    },
    downloadPDF(path, prefix){
      this.download = true;
      fetch(this.location+path+".pdf").then(response => response.blob()).then(res => {
        fileDownload(res, prefix+this.data.filename);
        this.download = false
      })
    },
    updateTable(_ticket, params){
      const staticFields = ["comment_check", "checkin_count", "gastro_count", "kitchen_comment", "gastro_comment", "welcome_drink", "drinks_flat_rate", "wardrobe_count", "send_invoice", "vegan_count", "color"]
      const ticket = _ticket || this.currentTicket

      const index = this.data.tickets.findIndex(x => x.id == ticket.id)
      
      if (_ticket){
        staticFields.forEach((f) => {
          this.data.tickets[index][f] = ticket[f]
        })
      } else{
        this.data.tickets[index].name = this.name
        this.data.tickets[index].vegan = this.isVegan
        this.data.tickets[index].comment = this.comment
        this.data.tickets[index].kitchen_comment = this.kitchen_comment
        this.data.tickets[index].gastro_comment = this.gastro_comment
        this.data.tickets[index].welcome_drink = this.welcomeDrink
        this.data.tickets[index].drinks_flat_rate = this.drinksFlatRate
        this.data.tickets[index].color = this.color
      }

      this.data.tickets.map((x, i) => {
        if (x.order_number == ticket.order_number){
          this.data.tickets[i].checkin_count = ticket.checkin_count

          if (_ticket){
            staticFields.forEach((f) => {
              this.data.tickets[i][f] = ticket[f]
            })
          } else {
            this.data.tickets[i].name = this.name
            this.data.tickets[i].comment = this.comment
            this.data.tickets[i].welcome_drink = this.welcomeDrink
            this.data.tickets[i].drinks_flat_rate = this.drinksFlatRate
            this.data.tickets[i].kitchen_comment = this.kitchen_comment
            this.data.tickets[i].gastro_comment = this.gastro_comment
            this.data.tickets[i].color = this.color
          }
        }
      })
      this.fetch(ticket.id, {...this.data.tickets[index], ...(params||{})})
    },
    action(evt){
      if (evt.action == "hover"){
        this.data.customerNumberHover = evt.customer_number
      } else if (evt.action == "move"){
        let index
        if (evt.from.id){
          index = this.data.tickets.findIndex(x => x.id == evt.from.id)

        }else{
          index = this.data.tickets.findIndex(x => x.table_number == evt.from.table && x.col_number == evt.from.col)
        }

        this.data.tickets[index].table_number = evt.to.table
        this.data.tickets[index].col_number = evt.to.col
        this.data.tickets[index].category = evt.to.category
        this.fetch(this.data.tickets[index].id, this.data.tickets[index])
      } else if (evt.action == "modal") {
        this.currentTicket = evt.ticket
        const modalTableElement = document.getElementById('modalTable')
        const modalTable = new bootstrap.Modal(modalTableElement)
        modalTable.show()
        this.name = evt.ticket.name
        this.isVegan = evt.ticket.vegan
        this.welcomeDrink = evt.ticket.welcome_drink
        this.drinksFlatRate = evt.ticket.drinks_flat_rate
        this.comment = evt.ticket.comment
        this.color = evt.ticket.color
        this.kitchen_comment = evt.ticket.kitchen_comment
        this.gastro_comment = evt.ticket.gastro_comment
      }
    },
    fetch(prefix, params){
      if (prefix) prefix = "/" + prefix
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: prefix ? "POST" : "GET",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      }

      let postfix = ".json"
      if (this.screen_id){
        postfix = `${postfix}?screen_id=${this.screen_id}`
      }else if (this.list == "true"){
        postfix = `${postfix}?no_import=true`
      }

      if (params){
        options.body = JSON.stringify(params)
      }
      if (!params || params.force){
        this.loading = true
      }


      fetch(this.location+(prefix||"")+postfix, options).then(x => x.json()).then((response) => {
        if (!params || params.force || params.clear){
          this.loading = false
          this.data = response
          if (this.q) {
            this.data.search = this.search
            this.search = this.q
          }

        }
      })
    }
  }
}
</script>

<style lang="scss">
  .drop-item{
    cursor: pointer;
  }
</style>