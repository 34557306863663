<template lang="pug">
.card-body
  h3 Farbe des Bändchen:
  .row
    .col-3(v-for="o in gastroOrders" @click="modalOpen(o.color)"): .my-3.d-flex.align-items-center(:style="{background: o.color, height: '150px', cursor: 'pointer', color: '#fff'}"): .d-block(style="width: 100%; text-align: center;"): strong.text-center {{o.name}}

#signatureModal.modal.fade(tabindex="-1", aria-hidden="true")
  .modal-dialog.modal-xl
    .modal-content(v-if="signature")
      .modal-header
        h1.modal-title.fs-5(v-html="signature.title")
        button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
      .modal-body: .row
        .col(v-html="signature.html")
        .col
          .mb-3
            label.form-label(for="name") Name der Person
            input.form-control#name(type="text", placeholder="Name der Person", v-model="name")
          div
            label.form-label Unterschrift
            vue-signature-pad(ref="signaturePad" height="200px" :customStyle="{ border: '1px solid #d9dee3', borderRadius: '0.375rem' }" v-if="signaturePadActive")
          .text-center: button.btn.btn-danger.btn-sm.mt-2(@click="$refs.signaturePad.clearSignature()" type="button") Unterschift verwerfen
          .d-grid: button.btn.btn-success.my-4.btn-lg(@click="saveSignature" type="button" :disabled="name.length === 0") Bestätigen
#bookingModal.modal.fade(tabindex="-1", aria-hidden="true")
  .modal-dialog.modal-xl
    .modal-content
      .modal-header
        h1.modal-title.fs-5(:style="{color: color}") Getränk buchen
        button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
      .modal-body
        .input-group.input-group-lg
          input.form-control(v-model="search" type="text" style="font-weight: 800; font-size: 24px;" placeholder="Suche")
        .text-center.my-4(v-if="product"): button.btn.btn-secondary(type="button"  @click="product=null") Zurück zu den Produkten
        .text-center.my-4(v-else-if="category"): button.btn.btn-secondary(type="button"  @click="category=null") Zurück zu den Kategorien
        .row
          .col-12.text-center(v-if="product")
            h2 {{product.name}}
            h5 {{toCurrency(product.price)}}
            .input-group.input-group-lg
              button.btn.btn-secondary(type="button" @click="amount=amount-1" :disabled="amount == 1") -
              input.form-control(aria-label='Anzahl' v-model="amount" style="text-align: center; font-weight: 800; font-size: 24px;" type="number" min="1")
              button.btn.btn-secondary(type="button" @click="amount=amount+1") +
            .d-grid: button.btn.btn-primary.mt-5.btn-lg(type="button" @click="sendBooking"): strong BUCHEN

          .col-12.col-sm-4(v-else-if="search.length != 0" v-for="c in productsBySearch" @click="product=c" style="cursor: pointer"): .card.bg-primary.text-center.p-3.m-3
            h2.text-white {{c.name}}
            h5.text-white {{toCurrency(c.price)}}

          .col-12.col-sm-4(v-else-if="category" v-for="c in productsByCategory(category)" @click="product=c" style="cursor: pointer"): .card.bg-primary.text-center.p-3.m-3
            h2.text-white {{c.name}}
            h5.text-white {{toCurrency(c.price)}}
          .col-12.col-sm-4(v-else v-for="c in categories" @click="category = c" style="cursor: pointer"): .card.bg-primary.text-center.p-3.m-3: h2.text-white {{c}}
#accordion.accordion.mt-4
  .card.accordion-item(v-for="o in data.gastro_orders")
    h2.accordion-header
      button.accordion-button(@click="active=(active == o.id ? '' : o.id)" :class="active == o.id ? 'active' : 'collapsed'" type="button" v-html="o.title")
    .accordion-collapse(:class="active == o.id ? 'show' : 'collapse'" data-bs-parent="#accordion")
      .accordion-body.py-2(v-html="o.html")
      button.btn.btn-primary.m-3.float-end(@click="copyToEvent(o.t_id)" type="button" v-if="data.other_event && o.t_id") Auf {{data.other_event.event_type}} kopieren
      button.btn.btn-primary.m-3(@click="signatureModalOpen(o)" type="button" v-if="!o.signature") Buchen und Unterschreiben
      .m-3(v-else)
        .row
          .col-sm-8.col-12
            strong {{o.signature.name}} hat am {{o.signature.date}} die Buchung bestätigt:
            div: img(:src="o.signature.data")
          .col-sm-4.col-12
            h5 Buchung bestätigen
            .alert.alert-success(v-if="o.signature.booking_date") {{o.signature.booking_date}}
            .input-group.mb-3(v-else)
              input.form-control(placeholder="Sicherheitscode" type="password" v-model="code")
              button.btn.btn-primary(@click="updateSignature(o.signature.id)" type="button" :disabled="code != '0815'") Buchung bestätigen
</template>

<script>
  import { VueSignaturePad } from 'vue-signature-pad';
export default {
  components: {
    VueSignaturePad
  },
  props: [
    "_data",
  ],
  channels: {
    GastroChannel: {
      received(data) { 
        this.fetch()
      },
    },
  },
  data () {
    return {
      code: "",
      signaturePadActive: false,
      name: "",
      signature: null,
      active: "",
      data: {},
      category: null,
      products: [],
      color: "",
      product: null,
      amount: 1,
      search: ""
    }
  },
  mounted () {
    this.data = this._data
    this.productFetch()

    this.$cable.subscribe({
      channel: "TicketingChannel::GastroChannel",
      token: this.data.channel_token
    }, "GastroChannel");

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) this.fetch()
    });
  },
  watch: {
  },
  computed: {
    gastroOrders(){
      if (!this.data.gastro_orders) return []
      return this.data.gastro_orders.filter( x => !x.signature)
    },
    productsBySearch(){
      const searchFields = ["id", "name", "category"]
      return this.products.filter(product => {
        return searchFields.map((x) => {
          return product && product[x] && this.search.split(" ").filter(q => q && q.length != 0).map((q) => {
            return String(product[x]).toLowerCase().indexOf(q.toLowerCase()) != -1
          }).indexOf(true) != -1
        }).indexOf(true) != -1
      })
    }, 
    // colors(){
    //   if (!this.data.tickets) return []
    //   let colors =  Object.keys(this.groupBy(this.data.tickets.filter(x => x.color != "#ffffff"), "color")).sort((a, b) => Number(a) - Number(b))
    //   colors.push("#03c3ec")
    //   return colors
    // },
    categories(){
      return Object.keys(this.groupBy(this.products, "category")).sort((a, b) => Number(a) - Number(b));
    }
  },
  methods: {
    copyToEvent(id){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({
          ticket_id: id,
          other_event_id: this.data.other_event.id
        }),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/gastro/products/booking/update", options)
    },
    fetch(){
      fetch(window.location.href.split("?")[0]+".json?no_import=true").then(x => x.json()).then((response) => {
        this.data = response
      })     
    },
    productFetch(){
      fetch("/gastro/products.json").then(x => x.json()).then((response) => {
        this.products = response
      })
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    productsByCategory(category){
      return this.products.filter(x => x.category == category)
    },
    sendBooking(){
      // window.bookingModal.hide()
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({
          amount: this.amount,
          product_id: this.product.id,
          color: this.color,
          room_plan_id: this.data.id
        }),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/gastro/products/booking", options)
      this.search = ""
      this.category = null
      this.product = null
      this.amount = 1
    },
    signatureModalOpen(o){
      if (this.$refs.signaturePad) this.$refs.signaturePad.clearSignature()
      this.signaturePadActive = false
      this.signature = o
      this.name = ""
      const signatureModalElement = document.getElementById('signatureModal')
      window.signatureModal = new bootstrap.Modal(signatureModalElement)
      window.signatureModal.show()
      let self = this
      setTimeout(function() {
        self.signaturePadActive = true
      }, 500)
    },
    modalOpen(color){
      this.search = ""
      this.category = null
      this.product = null
      this.amount = 1
      this.color = color
      const bookingModalElement = document.getElementById('bookingModal')
      window.bookingModal = new bootstrap.Modal(bookingModalElement)
      window.bookingModal.show()
    },
    updateSignature(id){
      console.log(id)
      this.code = ""
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({
          id: id,
        }),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/gastro/products/booking/signature", options)
    },
    saveSignature(){
      const data = this.$refs.signaturePad.saveSignature('image/svg+xml')
      this.$refs.signaturePad.clearSignature()
      window.signatureModal.hide()
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({
          name: this.name,
          data: data.data,
          color: this.signature.color,
          room_plan_id: this.data.id
        }),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/gastro/products/booking/signature", options)
      this.name = ""
    },
    toCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    }
  }
}
</script>

<style lang="scss">
</style>