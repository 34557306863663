<template lang="pug">
.card(style="height: 75vh;")
  .card-header: h1.mt-2(style="font-size: calc(1.2em + 1.35vw);") Anders gemeinelt als gesagt ...
  .card-body(v-if="success"): .alert.alert-success: h4.text-success Vielen Dank!
  .card-body(v-else-if="token")
    h2
      span.spinner-grow(role='status' v-if="!input")
      |  {{currentQuestion}}
    div(v-if="input")
      h3.text-danger()
        span.spinner-grow(role='status')
        |   Bitte sprechen
      textarea.form-control.form-control-lg(v-model="text")
      button.btn.btn-primary.btn-lg(@click="nextQuestion" style="position: absolute; bottom: 20px; width: 87%;") Weiter
  .card-body(v-else)
    p.text-center Bitte aktiviere den Ton auf deinem Gerät
    button.btn.btn-primary.btn-lg(style="position: absolute; bottom: 20px; width: 87%;" @click="start") Start

</template>

<script>
export default {
  components: {
  },
  props: [
    "body",
    "_token"
  ],
  data () {
    return {
      form: {},
      success: false,
      token: null,
      text: "",
      step: 0,
      input: false,
      recognition: null
    }
  },
  mounted () {
  },
  watch: {
  },
  computed: {
    currentQuestion(){
      return this.body.split("\n")[this.step]
    }
  },
  methods: {
    start(){
      this.token = this._token
      this.speechCurrentQuestion()
    },
    nextQuestion(){
      if (this.recognition) this.recognition.stop()
      this.form[this.currentQuestion] = this.text
      this.input = false
      this.text = ""
      this.step += 1
      
      if (this.step == this.body.split("\n").length){
        this.sendEmail()
        this.success = true
      }else{
        this.speechCurrentQuestion()
      }
    },
    setInputMode(){
      if(this.input) return false
      this.input = true
      this.recognition = new webkitSpeechRecognition() || new SpeechRecognition();

      this.recognition.interimResults = true;
      this.recognition.continuous = true;
      this.recognition.start();

      this.recognition.onresult = (event) => {
        const result = [...Array(event.results.length)].map((e, i) => {
          return event.results[i][0].transcript
        }).join(" ")
        if(this.input) this.text = result;
      }
    },
    speechCurrentQuestion(){
      let msg = new SpeechSynthesisUtterance(this.currentQuestion);
      msg.lang = "de"
      let self = this
      msg.onend = (event) => {
        this.setInputMode()
      };
      window.speechSynthesis.speak(msg);
      setTimeout(function(){
        self.setInputMode()
      },this.currentQuestion.length*80);
    },
    sendEmail(){
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({form: this.form}),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      fetch(window.location.href+"/send_email.json", options)
    }
  }
}
</script>

<style lang="scss">
</style>